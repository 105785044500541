import React, { useState, useRef, useEffect } from 'react';
import CardLayout from '../../../components/utils/CardLayout';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { useForm } from "react-hook-form";
import Button from '../../../components/utils/Button';
import Table from '../../../components/utils/Table';
import userAction from '../../../redux/user/actions'
import Tag from '../../../components/utils/Tag';
import SearchBar from '../../../components/utils/SearchBar';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import ArrowLeftFillIcon from 'remixicon-react/ArrowLeftFillIcon'
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Styles from './manualActivationReport.module.css';
import manualActivationReport from '../../../redux/manualActivationReport/actions'
import { Link } from 'react-router-dom';
import { convertDateTimeToRequiredFormatTZ } from '../../../helpers/commonFunction';


const { getUser } = userAction;
const { getManualActivationReport } = manualActivationReport;

const ManualActivationReport = () => {
    const dispatch = useDispatch();
    const userResult = useSelector((state) => state.user.userResult);
    const { manualActivationReport, loading } = useSelector((state) => state.manualActivationReport)
    const [toDate, setToDate] = useState(moment().startOf('day').toDate());
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').toDate());
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const [selectedDate, setSelectedDate] = useState()
    const format = process.env.REACT_APP_LONG_TIME_FORMAT
    const TIMEZONE = process.env.REACT_APP_TIMEZONE;
    const [searched, setSearched] = useState(false);
    const [filterText, setFilterText] = useState("");
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
    const [searchData, setSearchData] = useState({
        User: "",
        serialNumber: '',
        fromDate: fromDate,
        toDate: toDate,

    })

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'LOCKED BY', key: 'lockedBy' },
        { displayName: 'DATE', key: 'requestedDtTm' },
        { displayName: 'LOCKED DATE', key: 'CreatedDate' },
        { displayName: 'COMPLETED DATE', key: 'ModifiedDate' },
        { displayName: 'TIME TAKEN', key: 'timeTaken' },
        { displayName: 'STATUS', key: 'RequestStatus' },
        { displayName: 'TRANSACTIONID', key: 'transactionID' },
        { displayName: 'SERIAL/EID', key: 'serial' },
        // { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
        { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
    ];
    useEffect(() => {
        dispatch(getUser());
    }, [])

    useEffect(() => {
        const newData = {
            ...searchData,
            toDate: moment(toDate).format("YYYY-MM-DD"),
            fromDate: moment(fromDate).format("YYYY-MM-DD"),
        };
        dispatch(getManualActivationReport(newData));
    }, [])

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const onSubmit = (data) => {
        setSearched(true);
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSelectedDate(newData)
        dispatch(getManualActivationReport(newData))
    };
    const renderManualActivationTable = () => {
        if (searched) {
            return (
                <Table columns={columns} data={data} loading={loading} pagination={true} />
            );
        } else {
            return null;
        };
    }

    const exportToCSV = async () => {
        let tempData = manualActivationReport.map((item) => {
            delete item.AccountNumber
            return item;
        });
        if (data && data.length > 0) {
            const ws = XLSX.utils.json_to_sheet(tempData);
            const wb = { Sheets: { 'manualActivationReport': ws }, SheetNames: ['manualActivationReport'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Manual_Activation_report' + fileExtension);
        }
    }


    const filteredItems = Array.isArray(manualActivationReport) && manualActivationReport.length > 0 ? manualActivationReport.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];

    var data = filteredItems && filteredItems.length > 0 && filteredItems.map((Item, key) => {
        return {
            key: key + 1,
            lockedBy: Item.Name,
            requestedDtTm: Item.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(Item.RequestedDtTm) : '',
            CreatedDate: Item.CreatedDate !== null ? convertDateTimeToRequiredFormatTZ(Item.CreatedDate) : '',
            ModifiedDate: Item.ModifiedDate !== null ? convertDateTimeToRequiredFormatTZ(Item.ModifiedDate) : '',
            timeTaken: Item.DurationFormatted,
            RequestStatus: <Tag color={`${(Item.RequestStatus === 13) ? 'badge-danger' : (Item.RequestStatus === 11 || (Item.RequestStatus >= 18 && Item.RequestStatus <= 22) || Item.RequestStatus === 27) ? 'badge-warning' : (Item.RequestStatus === 12 || Item.RequestStatus === 23 || Item.RequestStatus === 24) ? 'badge-success' : (Item.RequestStatus === 32) ? 'badge-dark' : 'badge-secondary'}`} title={`${(Item.RequestStatus === 13) ? 'Cancelled' : (Item.RequestStatus === 11 || (Item.RequestStatus >= 18 && Item.RequestStatus <= 22)) ? 'Processing' : (Item.RequestStatus === 12 || Item.RequestStatus === 23 || Item.RequestStatus === 24) ? 'Processed' : Item.RequestStatus === 27 ? 'Assigned to Care' : (Item.RequestStatus === 32) ? 'Refunded' : 'Pending'}`}> </Tag>,
            transactionID: Item.TransactionID,
            serial: Item.SerialNumber,
            // accountNumber: Item.AccountNumber,
            mobileNumber: Item.MSISDN
        }
    });


    return (
        <CardLayout title="Manual Activation Report">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-row">
                    <div className=" col-lg-2 col-md-4 mb-3 d-flex flex-column">
                        <label>User</label>
                        <select className="form-control form-select" placeholder="Select User" name="userName"  {...register("userName", {
                            required: true,
                        })} >
                            <option value="0" key="">Select</option>
                            {userResult && userResult.length > 0 && userResult.map(data => (
                                <option value={data.Username} key={data.ID}>
                                    &nbsp;&nbsp; {data.Username}</option>

                            ))}
                        </select>
                    </div>
                    <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                        <label>From Date</label>
                        <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef}
                            selected={fromDate}
                            className="form-control"
                            onChange={(date) => setFromDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="fromDate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={fromDate}
                        />
                    </div>

                    <div className=" col-lg-2 col-md-4 mb-2 d-flex flex-column">
                        <label>To Date</label>
                        <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef2}
                            selected={toDate}
                            className="form-control center"
                            onChange={(date) => setToDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="todate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={toDate}
                        />
                        {errors.dealerName?.type === "required" && (
                            <div className="invalid-feedback">Date is required !</div>
                        )}
                    </div>
                    <div className=" col-md-4 col-lg-3 mb-2 d-flex flex-column">
                        <label>IMEI/Serial No/Mobile No</label>
                        <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/Serial No/Mobile No" {...register("serialNumber", {
                        })}></input>
                    </div>


                    <div className="col-lg-2 mt-4 col-md-4 mb-2 d-flex flex-column">
                        <Button title="Search" style="minWidth:80px" buttonClass={` btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />

                    </div>
                </div>

                {searched ?
                    <div className='d-flex justify-content-between mt-3'>
                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                        <div className='audit-report-Export-btn '>
                            <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit' size={'0.9rem'} />} title="Export" style={{ marginRight: "10px" }} buttonClass={`btn ${Styles.exportBtn}`} buttonStyle="primary" onClick={exportToCSV} />
                        </div>

                    </div>
                    : ""}
                {renderManualActivationTable()}
                {/* {searched ?
                <div className="text-right mt-2">
                      <Link to="/" className="btn btn-danger"><span><ArrowLeftFillIcon size="1.2em" style={{ color: "#ffff", marginRight: "1px",marginTop:'-3px' }} /></span>Back</Link>
                </div>
                : ""} */}
            </form>
        </CardLayout>
    );
};

export default ManualActivationReport;
