import React, { useState, useEffect } from 'react'
import CardLayout from '../../components/utils/CardLayout';
import SearchBar from '../../components/utils/SearchBar'
import SideButtons from '../../components/utils/SideButtons'
import Table from '../../components/utils/Table';
import Modal from '../../components/utils/Model';
import Button from '../../components/utils/Button';
import { useForm } from "react-hook-form";
import Tag from '../../components/utils/Tag'
import EyeFill from 'remixicon-react/EyeFillIcon';
import { Link } from 'react-router-dom';
import Popconfirm from '../../components/utils/Popover';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import PencilFill from 'remixicon-react/PencilFillIcon';
import DealerViewModel from '../../components/utils/DealerViewModel';
import AggregatorActions from '../../redux/aggregator/actions';
import tmoDealerAction from '../../redux/tMobileDealer/actions'
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/utils/Alert';
import { PASSWORD_VALIDATE } from '../../helpers/constant';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';

const { getActiveAggregator } = AggregatorActions;
const { getTmoDealer, addTmoDealer, prepareTmoDealerForm, resetForm, deleteTmoDealer, resetTmoDealerMessage } = tmoDealerAction;


const TMobileDealerLogin = () => {
    const dispatch = useDispatch();
    const [deleteItemId, setDeleteItemId] = useState()
    const [tmoDealerViewModal, setTmoDealerViewModal] = useState(false)
    const [formButton, setFormButton] = useState('')
    const [filterText, setFilterText] = useState("");
    const [formTitle, setFormTitle] = useState('');
    const [responseData, setResponseData] = useState([])
    const [editFlag, setEditFlag] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [ePayPasswordShow, setEPayPasswordShow] = useState(false);
    const [tMobilePasswordShow, setTMobilePasswordShow] = useState(false);
    const successMessage = useSelector((state) => state.tmoDealer.tmoDealerMessage);
    const errorMessage = useSelector((state) => state.tmoDealer.tmoDealerError);
    const { activeAggregatorList } = useSelector((state) => state.aggregator);
    const { tmoDealerResult, tmoDealerFormModal, loading } = useSelector((state) => state.tmoDealer)
    const deleteLoading = useSelector((state) => state.tmoDealer.deleteLoading);
    const [tmoDealerData, setTmoDealerData] = useState({
        activationID: '',
        tMODealerName: '',
        tMobileUsername: '',
        tMobilePassword: '',
        ePayUsername: '',
        ePayPassword: '',
        aggregatorID: '',
    })




    useEffect(() => {
        dispatch(getActiveAggregator())
        dispatch(getTmoDealer())
    }, []);

    useEffect(() => {
        if (errorMessage !== null && errorMessage !== undefined) {
            displayAlert(errorMessage, 'alert-danger');
        }
        if (successMessage !== null && successMessage !== undefined) {
            displayAlert(successMessage, 'alert-success');
        }
    }, [errorMessage, successMessage]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetTmoDealerMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetTmoDealerMessage());
    }


    const { register, handleSubmit, formState: { errors }, watch, reset, control } = useForm({
        defaultValues: tmoDealerData,
    });

    useEffect(() => {
        reset(tmoDealerData);
    }, [tmoDealerData]);


    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'DEALER NAME', key: 'dealerName' },
        { displayName: 'TMO USER NAME', key: 'tmoUsername' },
        { displayName: 'TMO PASSWORD', key: 'tmoPassword' },
        { displayName: 'E PAY USER NAME', key: 'ePayUser' },
        { displayName: 'E PAY PASSWORD', key: 'ePayPassword' },
        { displayName: 'AGGREGATOR', key: 'aggregatorId' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTIONS', key: 'action' },

    ];


    const filteredItems = Array.isArray(tmoDealerResult) && tmoDealerResult.length > 0 ? tmoDealerResult.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];

    var data = filteredItems && filteredItems.length > 0 && filteredItems.map((dealer, key) => {
        var actionContent = (
            <>
                <Link to="#">
                    <span data-toggle="tooltip" data-placement="top" title="Edit">
                        <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(dealer.ID)} />
                    </span>
                </Link>

                {deleteLoading && deleteItemId === dealer.ID ? <span className="spinner-border spinner-border-sm" role="status"></span> : dealer.IsActive === 1 ?
                    <Popconfirm
                        title="Are you sure to inactive this dealer?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(dealer.ID, 0)}
                    >
                        <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                            </span>
                        </Link>
                    </Popconfirm>

                    : <Popconfirm
                        title="Are you sure to active this dealer?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(dealer.ID, 1)}
                    >
                        <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Active">
                                <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                            </span>
                        </Link>
                    </Popconfirm>
                }

                <Link to="#"
                    onClick={() => handleShowDealerDetails(dealer)}
                >
                    <span data-toggle="tooltip" data-placement="top" title="View">
                        <EyeFill size="1.2em" style={{ color: "#294c28eb" }} />
                    </span>
                </Link>
            </>
        );

        return {
            key: key + 1,
            dealerName: dealer.TMODealerName,
            tmoUsername: dealer.TMobileUsername,
            tmoPassword: dealer.TMobilePassword,
            ePayUser: dealer.EPayUsername,
            ePayPassword: dealer.EPayPassword,
            aggregatorId: dealer.AggregatorName,
            status: <Tag color={`${dealer.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={dealer.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: actionContent,
        }
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleOpenAddFormModal = () => {
        setFormTitle('Add TMO User')
        setFormButton("Submit")
        dispatch(prepareTmoDealerForm());
        setTmoDealerData(prevState => ({
            ...prevState,
            activationID: "",
            tMODealerName: "",
            tMobileUsername: "",
            tMobilePassword: "",
            ePayUsername: "",
            ePayPassword: "",
            aggregatorID: "",
        }));

    }

    const onSubmit = (data) => {
        dispatch(addTmoDealer(data))
        reset(tmoDealerData);
    }


    const handleCancel = () => {
        reset(tmoDealerData)
        setTMobilePasswordShow(false);
        setEPayPasswordShow(false)
        dispatch(resetForm())
        setTmoDealerData(prevState => ({
            ...prevState,
            tMODealerName: '',
            tMobileUsername: '',
            tMobilePassword: '',
            ePayUsername: '',
            ePayPassword: '',
            aggregatorID: '',
        }));
    }
    const handleDelete = (ID, flag) => {
        setDeleteItemId(ID)
        dispatch(deleteTmoDealer(ID, flag))
    }
    const handleOpenEditFormModal = (dealerId) => {
        dispatch(prepareTmoDealerForm())
        setFormTitle('Update TMO User')
        setFormButton("Update")
        setEditFlag(true)
        var selectedDealer = tmoDealerResult && tmoDealerResult.filter(dealer => dealer.ID === dealerId);
        const id = activeAggregatorList && activeAggregatorList.length > 0 &&
            activeAggregatorList.filter(item => item.ID === selectedDealer[0].aggregatorId)
        setTmoDealerData(prevState => ({
            ...prevState,
            activationID: selectedDealer[0].ID,
            tMODealerName: selectedDealer[0].TMODealerName,
            tMobileUsername: selectedDealer[0].TMobileUsername,
            tMobilePassword: selectedDealer[0].TMobilePassword,
            ePayUsername: selectedDealer[0].EPayUsername,
            ePayPassword: selectedDealer[0].EPayPassword,
            aggregatorID: selectedDealer[0].AggregatorID,
        }));
    }


    const handleUpdate = (data) => {
        dispatch(addTmoDealer(data))
        reset(tmoDealerData);
        setTMobilePasswordShow(false);
        setEPayPasswordShow(false)
    }
    const handleShowDealerDetails = (dealerData) => {
        setTmoDealerViewModal(true)
        setResponseData(dealerData)
    }
    const handleCloseTmoViewModel = () => {
        setTmoDealerViewModal(false)
    }

    const responseItems = responseData ? () => {
        const dealerData = [
            [
                { name: "Dealer Name", value: responseData.TMODealerName },
                { name: "TMO User Name", value: responseData.TMobileUsername },
                { name: "TMO Password", value: responseData.TMobilePassword },
                { name: "E Pay User Name", value: responseData.EPayUsername },
                { name: "E Pay Password", value: responseData.EPayPassword },
                { name: "Aggregator", value: `${responseData.AggregatorName}` },
                { name: "Status", value: <Tag color={`${responseData.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={responseData.IsActive === 1 ? "Active" : "Inactive"} /> },
                //  { name: "Verified", value: <Tag color={`${responseData.IsVerified === 1 ? "badge-success" : "badge-danger"}`} title={responseData.IsVerified === 1 ? "Yes" : "No"} /> },
            ],
        ]
        return dealerData;
    } : [];
    const dealerItem = responseItems()



    return (
        <CardLayout title="T - Mobile Dealers">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row align-items-md-baseline">
                <div className='col-lg-6 col-md-6 od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
                <div className='col-lg-6 col-md-6 text-right'>
                    <SideButtons title="Add TMO Dealer" buttonStyle="primary" onClick={handleOpenAddFormModal} /></div></div>
            <Table columns={columns} data={data} loading={loading} pagination={true} />

            <Modal
                showModal={tmoDealerFormModal}
                title={formTitle}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Dealer Name</label>
                            <input type="text" placeholder="Enter dealer name" className="form-control"
                                name="tMODealerName" {...register("tMODealerName", {
                                    required: true,
                                })} />
                            {errors.tMODealerName?.type === "required" && (
                                <div className="invalid-feedback">Dealer name is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Select Aggregator</label>
                            <select className="form-control" placeholder="Select Country" name="aggregatorID"  {...register("aggregatorID", {
                                required: true,
                            })} >
                                <option value="" key="0">Select</option>
                                {activeAggregatorList && activeAggregatorList.length > 0 && activeAggregatorList.map(item => (
                                    <option name="countryID" value={item.ID} key={item.ID}>{item.AggregatorName}</option>
                                ))
                                }
                            </select>

                            {errors.aggregatorID?.type === "required" && (
                                <div className="invalid-feedback">Aggregator is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>TMO User Name</label>
                            <input type="text" placeholder="Enter TMO user name" className="form-control"
                                name="tMobileUsername" {...register("tMobileUsername", {
                                    required: true,
                                })} />
                            {errors.tMobileUsername?.type === "required" && (
                                <div className="invalid-feedback">TMO user name is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>TMO Password</label>
                            <input type={tMobilePasswordShow ? 'text' : 'password'} placeholder="Enter TMO password" className="form-control"
                                name="tMobilePassword" {...register("tMobilePassword", {
                                    required: true,
                                    validate: (value) => {
                                        return PASSWORD_VALIDATE.test(value)
                                    }
                                })} />
                            <div style={Styles.iconContainer}>
                                {
                                    tMobilePasswordShow ? <EyeLineIcon style={{ cursor: 'pointer' }} size="1.1em" onClick={() => setTMobilePasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setTMobilePasswordShow((pre) => !pre)} />
                                }
                            </div>
                            {errors.tMobilePassword?.type === "required" && (
                                <div className="invalid-feedback">TMO password is required !</div>
                            )}
                            {errors.tMobilePassword && errors.tMobilePassword.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format (Example@123) !</div> : ''}

                        </div>
                        <div className="col-md-6 mb-3">
                            <label>E Pay User Name</label>
                            <input type="text" placeholder="Enter e-pay user name" className="form-control"
                                name="ePayUsername" {...register("ePayUsername", {
                                    required: true,
                                })} />
                            {errors.ePayUsername?.type === "required" && (
                                <div className="invalid-feedback">E pay user name is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>E Pay Password</label>
                            <input type={ePayPasswordShow ? 'text' : 'password'} placeholder="Enter e-pay password" className="form-control"
                                name="ePayPassword" {...register("ePayPassword", {
                                    required: true,
                                    validate: (value) => {
                                        return PASSWORD_VALIDATE.test(value)
                                    }
                                })} />
                            <div style={Styles.iconContainer}>
                                {
                                    ePayPasswordShow ? <EyeLineIcon style={{ cursor: 'pointer' }} size="1.1em" onClick={() => setEPayPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setEPayPasswordShow((pre) => !pre)} />
                                }</div>
                            {errors.ePayPassword?.type === "required" && (
                                <div className="invalid-feedback">E pay password is required !</div>
                            )}
                            {errors.ePayPassword && errors.ePayPassword.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format (Example@123) !</div> : ''}

                        </div>
                        <div className='text-right row col-md-12 m-0'>
                            <div className="col-md-12 ml-3">
                                <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                                <Button title={formButton} loading={loading} style="minWidth:80px" buttonClass="btn btn-primary" buttonType='submit' />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>


            <DealerViewModel
                title={'TMO DEALER DETAILS'}
                responseDetailsData={dealerItem[0]}
                showViewModal={tmoDealerViewModal}
                handleCancel={handleCloseTmoViewModel}
            />

        </CardLayout>
    )
}

const Styles = {
    eyeIcon: {
        position: 'absolute',
        right: '12px',
        top: '42px',
        cursor: 'pointer'
    },
    iconContainer: {
        position: 'absolute',
        top: '36px',
        right: '12px'
    }
}

export default TMobileDealerLogin