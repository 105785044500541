import React from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import Button from '../../../components/utils/Button'
import "react-datepicker/dist/react-datepicker.css";
import Styles from './auditReport.module.css'
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import SearchBar from '../../../components/utils/SearchBar';
import Tag from '../../../components/utils/Tag'
import Table from '../../../components/utils/Table'
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import auditActions from '../../../redux/auditReport/actions'
import dealerActions from '../../../redux/dealer/actions';
import shortCodeActions from '../../../redux/shortCodeData/actions'
import { convertDateToRequiredFormatTZ, isAdmin, isDealer } from '../../../helpers/commonFunction';

const { getAuditReport } = auditActions;
const { getDealer } = dealerActions;
const { getShortCode } = shortCodeActions;

const AuditReport = () => {
  const dispatch = useDispatch();
  const { auditResult, loading,buttonLoading } = useSelector((state) => state.auditReport);
  const shortCodeResult = useSelector((state) => state.shortCode.shortCodeResult);
  const { dealerResult } = useSelector((state) => state.dealer);
  const { tokenData } = useSelector((state) => state.auth);
  const [toDate, setToDate] = useState(moment().startOf('day').toDate())
  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').toDate());
  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);
  const [filterText, setFilterText] = useState("");
  const [selectedDate, setSelectedDate] = useState()
  const [buttonId, setButtonId] = useState('');
  const dateFormat = process.env.REACT_APP_DATE_FORMAT;
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const TIMEZONE = process.env.REACT_APP_TIMEZONE;

  const predefinedPeriods = {
    today: moment().startOf('day').toDate(),
    yesterday: moment().subtract(1, 'days').startOf('day').toDate(),
    '15days': moment().subtract(15, 'days').startOf('day').toDate(),
    month: moment().subtract(30, 'days').startOf('day').toDate(),
    quarter: moment().subtract(3, 'months').startOf('day').toDate(),
    year: moment().subtract(1, 'year').startOf('day').toDate(),
  };
  const selectPeriodList = [
    { id: 1, value: 'today', title: 'Today' },
    { id: 2, value: 'month', title: 'Last 30 Days' },
    { id: 3, value: 'quarter', title: 'Quarter' },
    { id: 4, value: 'year', title: 'Year' }
  ]

  const [statusData, setstatusData] = useState({
    tableName: "t_account_audit_trail",
    fieldName: "EntryType"
  })

  useEffect(() => {
    const newData = {
      toDate: moment(toDate).format("YYYY-MM-DD"),
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
      transactionType: "",
      dealerId: "",
    };
    dispatch(getShortCode(statusData))
    dispatch(getAuditReport(newData));
    dispatch(getDealer());
  }, [])

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };
  const openDatePicker2 = () => {
    datePickerRef2.current.setOpen(true);
  };

  const handleFilterChange = (value) => {
    setFilterText(value);
  };

  const changeDateHandler = (item, id) => {
    if (selectPeriodList.filter((item, index) => item.id === id)) {
      setButtonId(id)
    }
    setFromDate(predefinedPeriods[item]);
    const formattedDate = moment(predefinedPeriods[item]).format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");
    const apiData = {
      fromDate: formattedDate,
      toDate: currentDate,
      transactionType: ""
    }
    dispatch(getAuditReport(apiData))
  }

  const onSubmit = (data) => {
    setButtonId('')
    const newData = {
      ...data,
      toDate: moment(toDate).format("YYYY-MM-DD"),
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
      // toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
      // fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
    };
    setSelectedDate(newData)
    dispatch(getAuditReport(newData))
  };

  let columns;
  if (isAdmin(tokenData)) {
    columns = [
      { displayName: '#', key: 'key' },
      { displayName: 'DATE', key: 'Date' },
      { displayName: 'PAYMENT TYPE', key: 'PaymentType' },
      { displayName: 'AMOUNT', key: 'Amount' },
      { displayName: 'PREVIOUS BALANCE', key: 'PreviousBalance' },
      { displayName: 'CURRENT BALANCE', key: 'CurrentBalance' },
      { displayName: 'DEALER NAME	', key: 'DealerName' },
      { displayName: 'REMARK', key: 'Remarks' },
      { displayName: 'ACCOUNTING', key: 'Accounting' },
    ];
  } else {
    columns = [
      { displayName: '#', key: 'key' },
      { displayName: 'DATE', key: 'Date' },
      { displayName: 'PAYMENT TYPE', key: 'PaymentType' },
      { displayName: 'AMOUNT', key: 'Amount' },
      { displayName: 'PREVIOUS BALANCE', key: 'PreviousBalance' },
      { displayName: 'CURRENT BALANCE', key: 'CurrentBalance' },
      { displayName: 'REMARK', key: 'Remarks' },
      { displayName: 'ACCOUNTING', key: 'Accounting' },
    ];
  }


  const filteredItems = Array.isArray(auditResult) && auditResult.length > 0 ? auditResult.filter(item => {
    if (typeof filterText !== 'string' || typeof item !== 'object') return false;
    // Check if any value in the object contains the filterText
    return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
}) : [];

  const data = filteredItems && filteredItems.length > 0 && filteredItems.map((auditItem, key) => {
    return {
      key: key + 1,
      // TransactionId: auditItem.TxnID,
      Remarks: auditItem.Remarks,
      PaymentType: auditItem.PaymentType,
      Amount: <span className={auditItem.Amount < 0 ? `text-danger` : `text-success`}>{`$${auditItem.Amount >= 0 ? auditItem.Amount : auditItem.Amount}`}</span>,
      PreviousBalance: <span className={auditItem.PreviousBalance < 0 ? `text-danger` : `text-success`}>{`$${auditItem.PreviousBalance >= 0 ? auditItem.PreviousBalance : auditItem.PreviousBalance}`}</span>,
      CurrentBalance: <span className={auditItem.CurrentBalance < 0 ? `text-danger` : `text-success`}>{`$${auditItem.CurrentBalance >= 0 ? auditItem.CurrentBalance : auditItem.CurrentBalance}`}</span>,
      DealerName: auditItem.DealerName,
      Date: convertDateToRequiredFormatTZ(auditItem.CreatedDtTm),
      Accounting: <Tag color={auditItem.TransactionType === 'Credit' ? "badge-success" : auditItem.TransactionType === 'Debit' ? "badge-warning" : "badge-danger"} title={auditItem.TransactionType}></Tag>
    }
  });

  const exportToCSV = async () => {
    let tempData = auditResult.map((item) => {
      if (isDealer(tokenData)) {
        delete item.DealerName;
        delete item.DealerID;
      }
      return item;
    });
    if (data && data.length > 0) {
      const ws = XLSX.utils.json_to_sheet(tempData);
      const wb = { Sheets: { 'auditResult': ws }, SheetNames: ['auditResult'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      await FileSaver.saveAs(data, 'Audit_report' + fileExtension);
    }
  }

  return (
    <CardLayout title="Audit Report">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">

          <div className="col-lg-2 mb-2 col-md-4 d-flex flex-column">
            <label>From Date</label>
            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
            <DatePicker
              ref={datePickerRef}
              selected={fromDate}
              className="form-control"
              onChange={(date) => setFromDate(date)}
              dateFormat="yyyy-MM-dd"
              name="fromDate"
              showMonthDropdown
              showYearDropdown
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={fromDate}
            />
          </div>

          <div className="col-lg-2 mb-2 col-md-4 d-flex flex-column">
            <label>To Date</label>
            <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
            <DatePicker
              ref={datePickerRef2}
              selected={toDate}
              className="form-control center"
              onChange={(date) => setToDate(date)}
              dateFormat="yyyy-MM-dd"
              name="todate"
              showMonthDropdown
              showYearDropdown
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={toDate}
            />
            {errors.dealerName?.type === "required" && (<div className="invalid-feedback">Date is required !</div>)}
          </div>

          <div className={`${isAdmin(tokenData) ? 'col-lg-2' : 'col-lg-3'} mb-2 col-md-4 d-flex flex-column`}>
            <label>Transaction Type</label>
            <select className="form-control" placeholder="Select" name="transactionType"  {...register("transactionType")} >
              <option value="" key="0" >Select</option>
              {/* <option value="2">Add Fund</option> */}
              {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.map((item, ind) => (
                <option value={item.ID}>{item.Value}</option>
              ))}
            </select>

          </div>
          {
            isAdmin(tokenData) ?
              <div className="col-lg-2 mb-2 d-flex flex-column col-md-4">
                <label>Dealer</label>
                <select className="form-control" placeholder="Select" name="dealerId"  {...register("dealerId")} >
                  <option value="" key="0" >Select Dealer</option>
                  {
                    dealerResult && dealerResult.length > 0 && dealerResult.filter((item) => item.IsCompany == 0).map((item) => {
                      return <option value={item.ID} key={`dealer${item.ID}`}>{item.DealerName}</option>;
                    })
                  }
                </select>
              </div> : ''
          }



          <div className={isAdmin(tokenData) ? `col-lg-2 mb-2 col-md-4 ` : `col-lg-2 `} >
            <div className="mb-2 mt-lg-4 mt-md-4">
              <Button title="Search" style="minWidth:80px" loading={buttonLoading} buttonClass={` btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
            </div>
          </div>


        </div>
        <div className='row'>
          <div className="col-md-6 ">
            <div className="form-row">
              <ul className='dat-tab-list'>
                {selectPeriodList.map((item, index) => <li className={`${buttonId === item.id ? 'text-danger' : 'text-primary'} `} style={{ cursor: 'pointer', textDecoration: 'underline' }} key={index} onClick={() => changeDateHandler(item.value, item.id)}>{item.title}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </form>

      <hr />

      {
        data.length > 500 ? <>
          <div className="card p-2 mob-w" style={{ margin: "12px auto", boxShadow: "rgb(190, 174, 172) 33px 35px 127px -41px", width: "50%" }} >
            <div className="card-inner p-5">
              <div className="text-center py-5">
                <h5 className="text-center">
                  Your report is ready for download! Click the link below to retrieve it.
                </h5>
                <p>
                  Note : The dataset you requested is too large to display directly.
                  Please download it using the link below in Excel format for better
                  accessibility.
                </p>
                <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit  ' size={'0.9rem'} />} title="Export" buttonClass={`btn ${Styles.exportBtn}`} style={{ marginRight: "10px" }} buttonStyle="primary" onClick={exportToCSV} />
              </div>
            </div>
          </div>

        </> : <>
          <div className='d-flex justify-content-between'>
            <SearchBar onFilter={handleFilterChange} filterText={filterText} />
            <div className='audit-report-Export-btn'><Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit ' size={'0.9rem'} />} title="Export" buttonClass={`btn ${Styles.exportBtn}`} style={{ marginRight: "10px" }} buttonStyle="primary" onClick={exportToCSV} /></div>
          </div>
          <Table columns={columns} data={data} loading={loading} pagination={true} />
        </>
      }

    </CardLayout>
  )
}

export default AuditReport;