import React from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../components/utils/Button'
import SearchBar from '../../../components/utils/SearchBar';
import Tag from '../../../components/utils/Tag'
import Table from '../../../components/utils/Table';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Styles from './activationReport.module.css';
import activationActions from '../../../redux/activationReport/actions'
import AggregatorActions from '../../../redux/aggregator/actions'
import networkActions from '../../../redux/network/actions';
import AuthActions from '../../../redux/auth/actions'
import shortCodeActions from '../../../redux/shortCodeData/actions'
import { convertDateTimeToRequiredFormatTZ, isAdmin, isAdminAgent, isDealer } from '../../../helpers/commonFunction';
import dealerAction from '../../../redux/dealer/actions'


const { getActivationReport } = activationActions;
const { getAggregator } = AggregatorActions;
const { getActiveNetwork } = networkActions;
const { getUserDataByToken } = AuthActions;
const { getShortCode } = shortCodeActions;
const { getDealer } = dealerAction;



function ActivationReport() {
    const dispatch = useDispatch();
    const tokenData = useSelector((state) => state.auth.tokenData);
    const { activationResult, loading } = useSelector((state) => state.activationReport);
    const shortCodeResult = useSelector((state) => state.shortCode.shortCodeResult);
    const { list } = useSelector((state) => state.aggregator);
    const { networkResult } = useSelector((state) => state.network);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const [toDate, setToDate] = useState(moment().startOf('day').toDate())
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').toDate());
    const datePickerRef = useRef(null);
    const dateFormat = process.env.REACT_APP_DATE_FORMAT;
    const TIMEZONE = process.env.REACT_APP_TIMEZONE;
    const datePickerRef2 = useRef(null);
    const [filterText, setFilterText] = useState("");
    const [selectedDate, setSelectedDate] = useState()
    const [searched, setSearched] = useState(false);
    const [buttonId, setButtonId] = useState('');
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [searchData, setSearchData] = useState({
        statusID: '',
        serialNumber: '',
        fromDate: fromDate,
        toDate: toDate,
    });

    // const predefinedPeriods = {
    //     today: moment().startOf('day').toDate(),
    //     yesterday: moment().subtract(1, 'days').startOf('day').toDate(),
    //     '15days': moment().subtract(15, 'days').startOf('day').toDate(),
    //     month: moment().subtract(30, 'days').startOf('day').toDate(),
    //     quarter: moment().subtract(3, 'months').startOf('day').toDate(),
    //     year: moment().subtract(1, 'year').startOf('day').toDate(),
    // };
    // const selectPeriodList = [
    //     { id: 1, value: 'today', title: 'Today' },
    //     { id: 2, value: 'month', title: 'Last 30 Days' },
    //     { id: 3, value: 'quarter', title: 'Quarter' },
    //     { id: 4, value: 'year', title: 'Year' }
    // ]
    const [statusData, setstatusData] = useState({
        tableName: "activationrequest",
        fieldName: "RequestStatus"
    })

    useEffect(() => {
        dispatch(getAggregator());
        dispatch(getActiveNetwork());
        dispatch(getUserDataByToken());
        dispatch(getShortCode(statusData))
        dispatch(getDealer());
    }, []);

    useEffect(() => {
        if (networkResult.length == 1) {
            reset({
                networkID: '1'
            });
        }
    }, [networkResult]);


    let columns;
    if ((isAdmin(tokenData) || isAdminAgent(tokenData))) {
        columns = [
            { displayName: '#', key: 'key' },
            { displayName: 'STATUS', key: 'masterStatus' },
            { displayName: 'TRANSACTIONID', key: 'transactionID' },
            { displayName: 'IMEI', key: 'IMEI' },
            { displayName: 'SERIAL/EID', key: 'serial' },
            { displayName: 'DEALER', key: 'DealerName' },
            // { displayName: 'ORDER NUMBER', key: 'orderNumber' },
            // { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
            { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
            { displayName: 'AGGREGATOR', key: 'aggregatorName' },
            { displayName: 'NETWORK', key: 'networkName' },
            // { displayName: 'E-PAY USERNAME', key: 'ePayUsername' },
            // { displayName: 'E-PAY TerminalID', key: 'epayTerminalID' },
            // { displayName: 'E-PAYTRANSACTIONID', key: 'epayTransactionID' },
            { displayName: 'AMOUNT', key: 'amountCharged' },
            { displayName: 'SUBMITTED DATE', key: 'requestedDtTm' },
            { displayName: 'COMPLETED DATE', key: 'ModifiedDate' },
            { displayName: 'SIM', key: 'simType' },
            { displayName: 'TYPE', key: 'requestType' },
            { displayName: 'PLAN MONTH', key: 'planMonth' },
            { displayName: 'ACTIVATION SOURCE', key: 'activationSource' },
            { displayName: 'REMARK', key: 'Remark' },
        ];
    } else {
        columns = [
            { displayName: '#', key: 'key' },
            { displayName: 'STATUS', key: 'masterStatus' },
            { displayName: 'DEALER', key: 'DealerName' },
            { displayName: 'TRANSACTIONID', key: 'transactionID' },
            { displayName: 'IMEI', key: 'IMEI' },
            { displayName: 'SERIAL/EID', key: 'serial' },
            // { displayName: 'ORDER NUMBER', key: 'orderNumber' },
            // { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
            { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
            { displayName: 'NETWORK', key: 'networkName' },
            // { displayName: 'E-PAY USERNAME', key: 'ePayUsername' },
            // { displayName: 'E-PAY TerminalID', key: 'epayTerminalID' },
            // { displayName: 'E-PAYTRANSACTIONID', key: 'epayTransactionID' },
            { displayName: 'AMOUNT', key: 'amountCharged' },
            { displayName: 'SUBMITTED DATE', key: 'requestedDtTm' },
            { displayName: 'COMPLETED DATE', key: 'ModifiedDate' },
            { displayName: 'SIM', key: 'simType' },
            { displayName: 'TYPE', key: 'requestType' },
            { displayName: 'PLAN MONTH', key: 'planMonth' },
            { displayName: 'ACTIVATION SOURCE', key: 'activationSource' },
            { displayName: 'REMARK', key: 'Remark' },
        ];
    }

    useEffect(() => {
        const newData = {
            ...searchData,
            toDate: moment(toDate).format("YYYY-MM-DD"),
            fromDate: moment(fromDate).format("YYYY-MM-DD"),
        };
        dispatch(getActivationReport(newData));
    }, [])


    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const renderActivationTable = () => {
        if (searched) {
            return (
                <div className='activation-report-table' id={'activationRequestTable'}><Table columns={columns} data={data} loading={loading} pagination={true} /></div>
            );
        } else {
            return null;
        };
    }

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const onSubmit = (data) => {
        setButtonId('')
        setSearched(true);
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSelectedDate(newData)
        dispatch(getActivationReport(newData))
    };

    const filteredItems = Array.isArray(activationResult) && activationResult.length > 0 ? activationResult.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];

    const data = filteredItems && filteredItems.length > 0 && filteredItems.map((Item, key) => {
        return {
            key: key + 1,
            transactionID: Item.TransactionID,
            DealerName: Item.DealerName,
            serial: Item.SerialNumber,
            IMEI: Item.IMEI,
            //ePayUsername: Item.EPayUsername,
            // orderNumber: Item.OrderNumber,
            mobileNumber: Item.MSISDN,
            // epayTerminalID: Item.EpayTerminalID,
            //epayTransactionID: Item.EpayTransactionID,
            // accountNumber: Item.AccountNumber,
            aggregatorName: Item.AggregatorID,
            networkName: Item.NetworkName,
            planMonth: Item.PlanMonth,
            requestType: Item.RequestType,
            activationSource: Item.ActivitySourceText,
            simType: Item.SimType,
            amountCharged: Item.AmountCharged != '' && Item.AmountCharged ? '$' + Item.AmountCharged : '',
            requestedDtTm: Item.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(Item.RequestedDtTm) : '',
            ModifiedDate: Item.ModifiedDate !== null ? convertDateTimeToRequiredFormatTZ(Item.ModifiedDate) : '',
            masterStatus: <Tag color={`${(Item.RequestStatusID === 13) ? 'badge-danger' : (Item.RequestStatusID === 11 || (Item.RequestStatusID >= 18 && Item.RequestStatusID <= 22) || Item.RequestStatusID === 27) ? 'badge-warning' : (Item.RequestStatusID === 12 || Item.RequestStatusID === 23 || Item.RequestStatusID === 24) ? 'badge-success' : (Item.RequestStatusID === 32) ? 'badge-info' : 'badge-secondary'}`} title={`${(Item.RequestStatusID === 13) ? 'Cancelled' : (Item.RequestStatusID === 11 || (Item.RequestStatusID >= 18 && Item.RequestStatusID <= 22)) ? 'Processing' : (Item.RequestStatusID === 12 || Item.RequestStatusID === 23 || Item.RequestStatusID === 24) ? 'Processed' : Item.RequestStatusID === 27 ? 'Assigned to Care' : (Item.RequestStatusID === 32) ? 'Refunded' : 'Pending'}`}> </Tag>,
            // status: (<span className={`${(Item.RequestStatusID === 13 || Item.RequestStatusID === 24) ? 'text-danger' : (Item.RequestStatusID === 11 || (Item.RequestStatusID >= 18 && Item.RequestStatusID <= 22) || Item.RequestStatusID === 27) ? 'text-warning' : (Item.RequestStatusID === 12 || Item.RequestStatusID === 23) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{Item.RequestStatus}</strong></span>),
            remark: Item.Remark,
        }
    });

    const exportToCSV = async () => {
        let id = 0;
        let formatData = activationResult && activationResult.length > 0 && activationResult.map((data, ind) => {
            id = id + 1;
            let newData = {
                ID: id,
                RequestStatus: data.RequestStatus,
                TransactionID: data.TransactionID,
                IMEI: data.IMEI,
                SerialNumber: data.SerialNumber,
                MSISDN: data.MSISDN,
                AggregatorID: data.AggregatorID ? data.AggregatorID : '',
                NetworkName: data.NetworkName,
                AmountCharged: data.AmountCharged,
                RequestedDtTm: data.RequestedDtTm,
                ModifiedDate: data.ModifiedDate,
                SimType: data.SimType,
                RequestType: data.RequestType,
                PlanMonth: data.PlanMonth,
                ActivitySourceText: data.ActivitySourceText,
                Remark: data.Remark,
                DealerName: data.DealerName,
            }
            if (isDealer(tokenData)) {
                delete newData.AggregatorID
            }
            return newData
        })
        if (data && data.length > 0) {
            const ws = XLSX.utils.json_to_sheet(formatData);
            const wb = { Sheets: { 'activationResult': ws }, SheetNames: ['activationResult'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Activation_report' + fileExtension);
        }
    }

    return (
        <CardLayout title="Activation Report">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">

                    <div className="col-lg-2 col-md-4 mb-2">
                        <label>Select Network</label>
                        <select className="form-control" placeholder="Select Network" name="networkID"  {...register("networkID", {
                        })} >
                            <option value="" key="0">All</option>
                            {networkResult.length > 0 && networkResult.map(network => (
                                <option value={network.ID} key={network.ID}>{network.NetworkName}</option>

                            ))}
                        </select>
                    </div>
                    {
                        (isAdmin(tokenData) || isAdminAgent(tokenData)) &&
                        <div className="col-lg-2 col-md-4  mb-2">
                            <label>Select Aggregator</label>
                            <select className="form-control form-select" placeholder="Select Aggregator" name="aggregatorID"  {...register("aggregatorID", {
                            })} >
                                <option value="" key="">All</option>
                                {list.length > 0 && list.filter((item) => item.IsActive).map(aggregator => (
                                    <option value={aggregator.ID} key={aggregator.ID}>{aggregator.ID}</option>
                                ))}
                            </select>
                        </div>
                    }

                    <div className="col-lg-2 col-md-4 mb-2 ">
                        <label>Select Status</label>
                        <select className="form-control" id="statusID" name="statusID" placeholder="Select"  {...register("statusID", {
                        })} >
                            <option value="" name="All">All</option>
                            {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.map((item, ind) => (
                                <option value={item.ID} name={item.Value}>{item.Value}</option>
                            ))}
                        </select>
                    </div>

                    {
                        (isAdmin(tokenData) || isAdminAgent(tokenData)) &&
                        <div className="col-lg-2 col-md-4  mb-2">
                            <label>Select Dealer</label>
                            <select className="form-control form-select" placeholder="Select Dealer" name="dealerID"  {...register("dealerID", {
                            })} >
                                <option value="" key="">All</option>
                                {dealerResult && dealerResult.length && dealerResult.map(item => (
                                    <option value={item.ID} key={item.ID}>{item.DealerName}</option>
                                ))}
                            </select>
                        </div>
                    }

                    <div className="col-lg-2 col-md-4 d-flex flex-column">
                        <label>From Date</label>
                        <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef}
                            selected={fromDate}
                            className="form-control"
                            onChange={(date) => setFromDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="fromDate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={fromDate}
                        />
                    </div>

                    <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                        <label>To Date</label>
                        <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef2}
                            selected={toDate}
                            className="form-control center"
                            onChange={(date) => setToDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="todate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={toDate}
                        />
                        {errors.dealerName?.type === "required" && (
                            <div className="invalid-feedback">Date is required !</div>
                        )}
                    </div>

                    <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                        <label>IMEI/SIM/Mobile/TXN</label>
                        <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/SIM/Mobile/TXN" {...register("serialNumber", {
                        })}></input>
                    </div>

                    <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? 'col-lg-2 col-md-4' : 'col-lg-2 col-md-4'}>
                        <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? "mt-2 d-flex justify-content-start activation-search-btn " : "mt-4"}>
                            <Button title="Search" style="minWidth:80px" buttonClass={` btn btn-primary sea-btn-tm btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
                        </div>
                    </div>

                    {/* <div className="col-md-6 ">
                        <div className="form-row">
                            {selectPeriodList.map((item, index) =>
                                <ul className='p-2 mr-4'>
                                    <li className={`${buttonId === item.id ? 'text-danger' : 'text-primary'} `} style={{ cursor: 'pointer', textDecoration: 'underline' }} key={index} onClick={() => changeDateHandler(item.value, item.id)}
                                    >{item.title}</li>
                                </ul>
                            )}
                        </div>
                    </div> */}
                </div>
                {searched ?
                    <div className='d-flex justify-content-between mt-3'>
                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                        <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit ' size={'0.9rem'} />} title="Export" style={{ marginRight: "10px" }} buttonClass={`btn ${Styles.exportBtn}  activation-Export-btn`} buttonStyle="primary" onClick={exportToCSV} />
                    </div>
                    : ""}
                {renderActivationTable()}
            </form>
        </CardLayout>
    )
}

export default ActivationReport