import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { set, useForm, Controller, useFieldArray } from "react-hook-form";
import { Link } from 'react-router-dom';
import CardLayout from '../../../components/utils/CardLayout';
import SearchBar from '../../../components/utils/SearchBar';
import "react-datepicker/dist/react-datepicker.css";
import Table from '../../../components/utils/Table';
import Alert from '../../../components/utils/Alert';
import Tag from '../../../components/utils/Tag';
import SimSwapActions from '../../../redux/simSwapRequest/actions';
import CompleteSimSwapModalComponent from './CompleteForm';
import CancelSimSwapModalComponent from './CancelForm';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import Button from '../../../components/utils/Button';
import DatePicker from "react-datepicker";
import shortCodeActions from '../../../redux/shortCodeData/actions'
import SideButtons from '../../../components/utils/SideButtons';
import SimSwapFormModal from './SimSwapFormModal';
import { isAdminOrAgent } from '../../../helpers/commonFunction';

const TIMEZONE = process.env.REACT_APP_TIMEZONE;
const LONG_TIME_FORMAT = process.env.REACT_APP_LONG_TIME_FORMAT;

const { getSimSwapRequest, lockSimSwapRequest, releaseLockSimSwapRequest, openCompleteSimSwapModal, openCancelSimSwapModal, openSimSwapFormModal, resetMessage } = SimSwapActions;
const { getShortCode } = shortCodeActions;

function ActivationRequest() {
    const dispatch = useDispatch();
    const {
        simSwapRequestList,
        simSwapRequestLoading,
        simSwapRequestError,
        lockSimSwapRequestError,
        lockSimSwapRequestMessage,
        completeSimSwapModal,
        cancelSimSwapModal,
        cancelSimSwapRequestError,
        cancelSimSwapRequestMessage, createSimSwapRequestMessage, releaseLockSimSwapRequestMessage, releaseLockSimSwapRequestError, lockSimSwapRequestLoading, releaseLockSimSwapRequestLoading, completeSimSwapRequestMessage } = useSelector((state) => state.simSwapRequest);
    const { shortCodeResult, shortCodeSimType } = useSelector((state) => state.shortCode);
    const { tokenData } = useSelector((state) => state.auth);
    const [toDate, setToDate] = useState(new Date())
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));

    const [filterText, setFilterText] = useState("");
    const [selectedRow, setSelectedRow] = useState(new Object());
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [selectedID, setSelectedID] = useState('');
    const [unlockID, setunlockID] = useState('');
    const [cancelID, setcancelID] = useState('');
    const [completeID, setcompleteID] = useState('');
    const [searchData, setSearchData] = useState({
        statusID: '10',
        serialNumber: '',
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
    });
    const [statusData, setstatusData] = useState({
        tableName: "activationrequest",
        fieldName: "RequestStatus"
    });

    const { register: registerFilter, handleSubmit: handleSubmit, formState: { errors: filterError }, watch, reset } = useForm({ defaultValues: searchData });


    useEffect(() => {
        const newData = {
            ...searchData,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        dispatch(getSimSwapRequest(newData));
        dispatch(getShortCode(statusData));
    }, [])

    useEffect(() => {
        if (createSimSwapRequestMessage || releaseLockSimSwapRequestMessage || lockSimSwapRequestMessage) {
            dispatch(dispatch(getSimSwapRequest(searchData)));
        }
    }, [createSimSwapRequestMessage, releaseLockSimSwapRequestMessage, lockSimSwapRequestMessage])

    useEffect(() => {
        if (cancelSimSwapRequestError !== null && cancelSimSwapRequestError !== undefined) {
            displayAlert(cancelSimSwapRequestError, 'alert-danger');
        }
        if (cancelSimSwapRequestMessage !== null && cancelSimSwapRequestMessage !== undefined) {
            displayAlert(cancelSimSwapRequestMessage, 'alert-success');
        }
        if (lockSimSwapRequestError !== null && lockSimSwapRequestError !== undefined) {
            displayAlert(lockSimSwapRequestError, 'alert-danger');
        }
        if (completeSimSwapRequestMessage !== null && completeSimSwapRequestMessage !== undefined) {
            displayAlert(completeSimSwapRequestMessage, 'alert-success');
        }

        if (releaseLockSimSwapRequestError !== null && releaseLockSimSwapRequestError !== undefined) {
            displayAlert(releaseLockSimSwapRequestError, 'alert-danger');
        }
        if (releaseLockSimSwapRequestMessage !== null && releaseLockSimSwapRequestMessage !== undefined) {
            displayAlert(releaseLockSimSwapRequestMessage, 'alert-success');
        }
        if (simSwapRequestError !== null && simSwapRequestError !== undefined) {
            displayAlert(simSwapRequestError, 'alert-danger');
        }
        if (createSimSwapRequestMessage !== null && createSimSwapRequestMessage !== undefined) {
            displayAlert(createSimSwapRequestMessage, 'alert-success');
        }
    }, [simSwapRequestError, completeSimSwapRequestMessage, lockSimSwapRequestError, , releaseLockSimSwapRequestMessage, lockSimSwapRequestMessage, cancelSimSwapRequestError, cancelSimSwapRequestMessage, createSimSwapRequestMessage]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetMessage())
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetMessage())
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ACTION', key: 'action' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'TRANSACTION ID', key: 'transactionId' },
        // { displayName: 'IMEI', key: 'imei' },
        { displayName: 'SERIAL/EID', key: 'serialNumber' },
        { displayName: 'NETWORK', key: 'NetworkName' },
        { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
        // { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
        { displayName: 'DATE', key: 'requestedDtTm' },
        { displayName: 'TYPE', key: 'requestType' },
        { displayName: 'SIM', key: 'simType' },
        { displayName: 'REMARK', key: 'remark' },
    ];

    const filteredItems = Array.isArray(simSwapRequestList) && simSwapRequestList.length > 0 ? simSwapRequestList.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];

    const handleUnLockActivation = (activationID) => {
        setunlockID(activationID)
        let data = {
            simswapID: activationID
        }
        dispatch(releaseLockSimSwapRequest(data, searchData));
        alertCancel()
    }

    const handleLockActivation = (activationID) => {
        setSelectedID(activationID)
        let data = {
            simswapID: activationID
        }
        dispatch(lockSimSwapRequest(data, searchData));
        alertCancel()
    }

    const handleCancelRequest = (activationID) => {
        setcancelID(activationID)
        let selectedActivation = simSwapRequestList.filter((item) => item.ID === activationID);
        setSelectedRow(selectedActivation[0]);
        dispatch(openCancelSimSwapModal());
        alertCancel()
    }

    const handleCompleteRequest = (activationID) => {
        setcompleteID(activationID)
        let selectedActivation = simSwapRequestList.filter((item) => item.ID === activationID);
        setSelectedRow(selectedActivation[0]);
        dispatch(openCompleteSimSwapModal());
        alertCancel()
    }

    const data = filteredItems && filteredItems.length > 0 && filteredItems.map((activation, key) => {
        return {
            key: key + 1,
            action: <>
                {
                    isAdminOrAgent(tokenData) ? <>
                        {
                            activation.RequestStatusID === 10 ? activation.otherLockFlag === 1 ?
                                <div className='d-flex'>
                                    <div className="icon iq-action-icon-box rounded-circle bg-danger"> <i className="ri-forbid-line"></i></div>
                                </div> :
                                <>
                                    {
                                        activation.lockFlag === 1 ?
                                            <div className='d-flex'>
                                                <div className="icon iq-action-icon-box rounded-circle bg-success" onClick={() => handleCompleteRequest(activation.ID)}><span data-toggle="tooltip" data-placement="top" title="Complete Request"> <i className="ri-check-line"></i></span></div>
                                                <div className="icon iq-action-icon-box rounded-circle bg-danger" onClick={() => handleCancelRequest(activation.ID)}><span data-toggle="tooltip" data-placement="top" title="Cancel Request"> <i className="ri-close-fill"></i></span></div>
                                                <div className="icon iq-action-icon-box rounded-circle bg-secondary" onClick={() => handleUnLockActivation(activation.ID)}>
                                                    {releaseLockSimSwapRequestLoading && unlockID == activation.ID ? <span className="spinner-border spinner-border-sm" style={{ marginBottom: '2px' }} role="status"></span> : <span data-toggle="tooltip" data-placement="top" title="Unlock"><i className="ri-lock-unlock-line"></i></span>}
                                                </div>
                                            </div> :
                                            <div className='d-flex'>
                                                <div className="icon iq-action-icon-box rounded-circle" style={{ background: '#2c3782', color: '#fff' }} onClick={() => handleLockActivation(activation.ID)}>
                                                    {lockSimSwapRequestLoading && selectedID == activation.ID ?
                                                        <span className="spinner-border spinner-border-sm" style={{ marginBottom: '2px' }} role="status"></span>
                                                        : <span data-toggle="tooltip" data-placement="top" title="Lock"><i className="ri-lock-fill"></i></span>}
                                                </div>
                                            </div>
                                    }
                                </> : ''
                        }
                    </> : ''
                }
            </>,
            serialNumber: activation.SerialNumber,
            NetworkName: activation.NetworkName,
            transactionId: <Link to="#">{activation.TransactionID}</Link>,
            // imei: activation.IMEI,
            mobileNumber: activation.MSISDN,
            // accountNumber: activation.AccountNumber,
            requestedDtTm: activation.RequestedDtTm !== null ? moment(new Date(activation.RequestedDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`) : '',
            requestType: activation.RequestType,
            simType: activation.SimType,
            status: <Tag
                color={`${(activation.RequestStatusID === 13) ? 'badge-danger' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22) || activation.RequestStatusID === 27) ? 'badge-warning' : (activation.RequestStatusID === 12) ? 'badge-success' : 'badge-secondary'}`}
                title={`${(activation.RequestStatusID === 13) ? 'Cancelled' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22)) ? 'Processing' : (activation.RequestStatusID === 12) ? 'Processed' : activation.RequestStatusID === 27 ? 'Assigned to Care' : 'Pending'}`}
            />,
            remark: activation.Remark,
        }
    });

    const completeSimSwapModalFunction = () => {
        return <CompleteSimSwapModalComponent row={selectedRow} search={searchData} />;
    }

    const cancelSimSwapModalFunction = () => {
        return <CancelSimSwapModalComponent row={selectedRow} search={searchData} />;
    }

    const onSubmit = (data) => {
        alertCancel()
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSearchData(newData)
        dispatch(dispatch(getSimSwapRequest(newData)));
    }
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const handleOpenAddFormModal = () => {
        dispatch(openSimSwapFormModal())
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
    }

    return (
        <>
            <CardLayout title="Sim Swap Request">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className="col-lg-2 col-md-4 mb-2">
                            <label>Select Status</label>
                            <select className="form-control" id="statusID" name="statusID" placeholder="Select"  {...registerFilter("statusID", {
                            })} >
                                {shortCodeResult && shortCodeResult.length > 0 && <option value="" name="All">All</option>}
                                <option value={'10'} name={'Pending'}>Pending</option>
                                {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.filter(item => item.ID != 10).map((item, ind) => (
                                    <option value={item.ID} name={item.Value}>{item.Value}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                            <label>From Date</label>
                            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef}
                                selected={fromDate}
                                className="form-control"
                                onChange={(date) => setFromDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="fromDate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={fromDate}
                            />
                        </div>

                        <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                            <label>To Date</label>
                            <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef2}
                                selected={toDate}
                                className="form-control center"
                                onChange={(date) => setToDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="todate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={toDate}
                            />
                        </div>

                        <div className="col-lg-2 col-md-4 mb-2">
                            <label>Serial No/TXN Id/Mobile No</label>
                            <input
                                type="text"
                                className="form-control"
                                id="serialNumber"
                                name="serialNumber"
                                placeholder="Serial/TXN/Mobile"
                                {...registerFilter("serialNumber")}
                            />
                        </div>

                        <div className={'col-lg-2 col-md-4'}>
                            <div className={"mt-2 d-flex justify-content-start activation-search-btn"}>
                                <Button title="Search" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
                            </div>
                        </div>

                    </div>
                </form>
                <hr />

                <div className="row align-items-baseline">
                    <div className='col-lg-6 col-md-6  od-2'>
                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                    </div>
                    <div className='col-lg-6 col-md-6  text-right'>
                        {
                            <SideButtons title="Add SIM Swap" buttonStyle="primary" onClick={handleOpenAddFormModal} />
                        }</div>

                </div>
                {/* <div className="row mb-3">
                    <div className='col-lg-6 col-md-6  od-2'>
                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                    </div>
                </div> */}

                {completeSimSwapModal ? completeSimSwapModalFunction() : ''}
                {cancelSimSwapModal ? cancelSimSwapModalFunction() : ''}

                {showAlert && <div className='mt-4 mb-0'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
                <Table columns={columns} loading={simSwapRequestLoading} data={data} pagination={true} />
                <SimSwapFormModal />
            </CardLayout>
        </>
    )
}

const styles = {
    multilineActivation: {
        color: "#e74c3c",
        border: "1px solid",
        padding: "3px 5px",
        borderRadius: "9px"
    }
}

export default ActivationRequest;