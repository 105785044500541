import React, { useEffect, useState } from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import Table from '../../../components/utils/Table'
import Tag from '../../../components/utils/Tag'
import { Link } from 'react-router-dom';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import Popconfirm from '../../../components/utils/Popover';
import EyeFill from 'remixicon-react/EyeFillIcon';
import LinksLineIcon from 'remixicon-react/LinksLineIcon'
import SideButtons from '../../../components/utils/SideButtons';
import SearchBar from '../../../components/utils/SearchBar';
import Modal from '../../../components/utils/Model';
import Button from '../../../components/utils/Button';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Alert from '../../../components/utils/Alert';
import { useDispatch, useSelector } from 'react-redux';
import rechargePlanAction from '../../../redux/rechargePlan/actions'
import networkActions from '../../../redux/network/actions'
import moment from "moment";
import AggregatorActions from '../../../redux/aggregator/actions'
import { convertDateToRequiredFormatTZ } from '../../../helpers/commonFunction';
import Select from 'react-select';



const { openRechargePlanModal, closeRechargePlanModal, getRechargePlan, addRechargePlan, updateRechargePlan, deleteRechargePlan, resetRechargePlanMessage, openPreferedAggregatorModalRecharge, closePreferedAggregatorModalRecharge, updatePreffedAggregatorRecharge } = rechargePlanAction;
const { getActiveNetwork } = networkActions;


const RechargePlan = () => {
    const dateFormat = process.env.REACT_APP_DATE_FORMAT;
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState("");
    const [formButton, setFormButton] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [formTitle, setFormTitle] = useState('');
    const [isRangeBox, setIsRangBox] = useState(false);
    const [selectedId, setSelectedId] = useState('')
    const [selectedIsRange, setSelectedIsRange] = useState('')
    const [planItemId, setPlanItemId] = useState()
    const [networkId, setNetworkId] = useState(0);
    const [rechargePlanId, setrechargePlanId] = useState(0);
    const [searched, setSearched] = useState(false);
    const [rechargePlanSearchData, setRechargePlanSearchData] = useState({
        network: "",
    })

    const [rechargePlanData, setRechargePlanData] = useState({
        PlanName: '',
        NetworkID: '',
        IsRange: '',
        MinPrice: '',
        MaxPrice: '',
        RechargePrice: '',
        PlanDesc: '',
        aggregatorData: [],
    })


    const { networkResult } = useSelector((state) => state.network);
    const rechargePlanModal = useSelector((state) => state.rechargePlan.rechargePlanModal);
    const { list, listGetByNetworkId, aggregatorListByNetworkIdForPlan } = useSelector((state) => state.aggregator);
    const [buttonText, setButtonText] = useState();
    const { getAggregator, getAggregatorByNetworkID, getAggregatorByNetworkIDForPlan } = AggregatorActions;


    const { getRechargePlanList, planMessage, planError, getRechargePlanLoading, addRechargePlanMessage, addRechargePlanError, addRechargePlanLoading, deleteButtonLoading, preferedAggregatorModal, buttonLoading } = useSelector((state) => state.rechargePlan);

    const { register, handleSubmit, formState: { errors }, watch, reset, control, setValue } = useForm({
        defaultValues: rechargePlanData,
    });
    const { register: registerregister, handleSubmit: handleRechargePlanSubmit, watch: watchNetworkForm, formState: { errors: errorerror }, control: searchControl } = useForm({
        defaultValues: rechargePlanSearchData,
    });
    const { fields, append, remove, replace } = useFieldArray({
        name: "aggregatorData",
        control,
    });
    const [prefferedAggregatorForm, setPrefferedAggregatorForm] = useState({
        // PrefferedAggregatorId: "",
        RechargeAggregatorId: ""
    });

    const { register: registerReset, handleSubmit: handleSubmitReset, watch: watchReset, formState: { errors: errorsReset }, reset: resetReset } = useForm({
        defaultValues: prefferedAggregatorForm
    });

    useEffect(() => {
        dispatch(getActiveNetwork());
        dispatch(getAggregator());
        // resetReset()
    }, [])

    useEffect(() => {
        reset(rechargePlanData);
    }, [rechargePlanData]);


    useEffect(() => {
        if (aggregatorListByNetworkIdForPlan && aggregatorListByNetworkIdForPlan.length > 0) {
            resetAggregatorData(aggregatorListByNetworkIdForPlan);
        }
    }, [aggregatorListByNetworkIdForPlan]);

    const resetAggregatorData = (aggregators) => {
        const defaultAggregatorData = aggregators.map((item) => ({
            aggregatorID: item.ID,
            key: item.AggregatorName,
            value: '',
        }));
        setValue('aggregatorData', defaultAggregatorData);
    };

    useEffect(() => {
        if (addRechargePlanMessage) {
            setRechargePlanData(prevState => ({
                ...prevState,
                PlanName: '',
                NetworkID: '',
                IsRange: '',
                MinPrice: '',
                MaxPrice: '',
                RechargePrice: '',
                PlanDesc: '',
                aggregatorData: [],
            }));
        }
    }, [addRechargePlanMessage]);

    useEffect(() => {
        if (listGetByNetworkId && listGetByNetworkId.length > 0 && networkId != 0) {
            // let selectedRow = networkResult.filter((item) => item.ID == networkId);
            let selectedRow = getRechargePlanList.filter((item) => item.ID == rechargePlanId);

            if (selectedRow.length > 0) {
                resetReset({
                    RechargeAggregatorId: selectedRow[0].RechargeAggregator ? selectedRow[0].RechargeAggregator.toString() : ""
                });
            }
        }
    }, [listGetByNetworkId, rechargePlanId]);

    useEffect(() => {
        if (planError !== null && planError !== undefined) {
            displayAlert(planError, 'alert-danger');
        }
        if (planMessage !== null && planMessage !== undefined) {
            displayAlert(planMessage, 'alert-success');
        }
        if (addRechargePlanError !== null && addRechargePlanError !== undefined) {
            displayAlert(addRechargePlanError, 'alert-danger');
        }
        if (addRechargePlanMessage !== null && addRechargePlanMessage !== undefined) {
            displayAlert(addRechargePlanMessage, 'alert-success');
        }


    }, [addRechargePlanMessage, addRechargePlanError, planMessage, planError]);

    let columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'PLAN NAME', key: 'planName' },
        { displayName: 'NETWORK', key: 'network' },
        { displayName: 'IS RANGE', key: 'isRange' },
        { displayName: 'MIN', key: 'min' },
        { displayName: 'MAX', key: 'max' },
        { displayName: 'PRICE', key: 'price' },
        { displayName: 'PREFERRED AGGREGATOR', key: 'preferredAggregator' },
        { displayName: 'CREATED BY', key: 'createdBy' },
        { displayName: 'CREATED DATE', key: 'createdDate' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTION', key: 'action' },
    ];

    const filteredItems = Array.isArray(getRechargePlanList) && getRechargePlanList.length > 0 ? getRechargePlanList.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];


    const dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((item, key) => {
        let networkData = networkResult && networkResult.length > 0 && networkResult.filter(network => network.ID == item.NetworkID);
        let networkName = networkData && networkData.length > 0 && networkData[0].NetworkName
        const rechargeAggregator = list && list.length > 0 && list.filter(data => data.ID == item.RechargeAggregator)
        var actionContent = (
            <>
                <Link to="#">
                    <span data-toggle="tooltip" data-placement="top" title="Edit">
                        <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(item.ID)} />
                    </span>
                </Link>
                {deleteButtonLoading && planItemId === item.ID ? <span className="spinner-border spinner-border-sm" role="status"></span> :
                    item.IsActive === 1 ? (
                        <Popconfirm
                            title="Are you sure to inactive this recharge plan?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(item.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                    <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>

                    ) : (
                        <Popconfirm
                            title="Are you sure to active this recharge plan?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(item.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Active">
                                    <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                    )}
                <Link to="#" onClick={() => handlepreferredAggregatorModal(item.NetworkID, item.ID)}>
                    <span data-toggle="tooltip" data-placement="top" title="Manage aggregator">
                        <LinksLineIcon size="1.2em" style={{ color: "#0094b3", marginRight: "3px" }} />
                    </span>
                </Link>
            </>
        );

        return {
            key: key + 1,
            planName: item.PlanName,
            network: networkName ? networkName : 'NA',
            preferredAggregator: rechargeAggregator && rechargeAggregator.length > 0 ? rechargeAggregator[0].AggregatorName : '',
            isRange: item.IsRange === 1 ? 'Yes' : 'No',
            min: item.IsRange === 1 ? `$${item.MinPrice}` : '0.00',
            max: item.IsRange === 1 ? `$${item.MaxPrice}` : '0.00',
            price: item.IsRange === 1 ? 'NA' : `$${item.RechargePrice}`,
            createdBy: item.Name,
            createdDate: convertDateToRequiredFormatTZ(item.CreatedDtTm),
            status: <Tag color={`${item.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={item.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: actionContent,
        }
    });

    const handlepreferredAggregatorModal = (id, rechargeId) => {
        dispatch(getAggregatorByNetworkID(id));
        setFormTitle('Manage Aggregator')
        setButtonText('Submit');
        setNetworkId(id);
        dispatch(openPreferedAggregatorModalRecharge());
        setrechargePlanId(rechargeId)
    }

    const handleUpdatePrefferedAggregator = (data) => {
        data.RechargeID = rechargePlanId;
        dispatch(updatePreffedAggregatorRecharge(data, rechargePlanSearchData.network));
    }

    const handleCancelModal = () => {
        dispatch(closePreferedAggregatorModalRecharge());
    };

    const handleOpenAddFormModal = () => {
        setEditFlag(false);
        dispatch(openRechargePlanModal());
        setFormTitle('Add Recharge Plan')
        setFormButton("Submit")
    }

    const handleOpenEditFormModal = (planID) => {
        setSelectedId(planID)
        dispatch(openRechargePlanModal());
        setEditFlag(true);
        setFormTitle('Update Recharge Plan')
        setFormButton("Update")
        var selectedPlan = getRechargePlanList && getRechargePlanList.filter(item => item.ID === planID);
        let newAggregatorData = [
            {
                aggregatorID: 1,
                key: 'ENK Wireless',
                value: selectedPlan[0].TMobilePlanID
            },
            {
                aggregatorID: 3,
                key: 'PrepaidIQ',
                value: selectedPlan[0].PrepaidIQPlanID
            },
            {
                aggregatorID: 4,
                key: 'ENK Boom',
                value: selectedPlan[0].BoomPlanID
            },
            {
                aggregatorID: 7,
                key: 'Surf',
                value: selectedPlan[0].SurfPlanID
            },
            {
                aggregatorID: 8,
                key: 'Generic Recharge',
                value: selectedPlan[0].LycaRechargePlanID
            },
        ]
        const range = selectedPlan && selectedPlan[0].IsRange ? true : false;
        setSelectedIsRange(range)
        if (range) {
            setIsRangBox(true)
        }
        else {
            setIsRangBox(false)
        }

        newAggregatorData = newAggregatorData.filter(item => item.value != 0 && item.value != null)
        setRechargePlanData(prevState => ({
            ...prevState,
            PlanName: selectedPlan[0].PlanName,
            NetworkID: selectedPlan[0].NetworkID,
            IsRange: selectedPlan[0].IsRange ? selectedPlan[0].IsRange : '',
            MinPrice: selectedPlan[0].MinPrice,
            MaxPrice: selectedPlan[0].MaxPrice,
            RechargePrice: selectedPlan[0].RechargePrice == 0 ? '' : selectedPlan[0].RechargePrice,
            PlanDesc: selectedPlan[0].PlanDesc,
            aggregatorData: newAggregatorData
        }));
    }

    const onSubmit = (data) => {
        const isRange = data && data.IsRange;
        if (isRange) {
            let addData1 = {
                PlanName: data.PlanName,
                MinPrice: data.MinPrice,
                MaxPrice: data.MaxPrice,
                IsRange: 1,
                NetworkID: data.NetworkID,
                PlanDesc: data.PlanDesc,
                RechargePrice: 0,
                aggregatorData: data.aggregatorData,

            }
            dispatch(addRechargePlan(addData1, rechargePlanSearchData.network))
        }
        else {
            let addData2 = {
                PlanName: data.PlanName,
                RechargePrice: data.RechargePrice,
                NetworkID: data.NetworkID,
                PlanDesc: data.PlanDesc,
                aggregatorData: data.aggregatorData,
            }
            dispatch(addRechargePlan(addData2, rechargePlanSearchData.network))
        }
    }

    const handleUpdate = (data) => {
        const isRange = data && data.IsRange;
        if (isRange) {
            let addData1 = {
                RechargePlanId: selectedId,
                PlanName: data.PlanName,
                MinPrice: data.MinPrice,
                MaxPrice: data.MaxPrice,
                IsRange: 1,
                NetworkID: data.NetworkID,
                PlanDesc: data.PlanDesc,
                RechargePrice: 0,
                aggregatorData: data.aggregatorData,
            }
            dispatch(updateRechargePlan(addData1, rechargePlanSearchData.network))
        }
        else {
            let addData2 = {
                RechargePlanId: selectedId,
                PlanName: data.PlanName,
                RechargePrice: data.RechargePrice,
                NetworkID: data.NetworkID,
                PlanDesc: data.PlanDesc,
                aggregatorData: data.aggregatorData,
            }
            dispatch(updateRechargePlan(addData2, rechargePlanSearchData.network))
        }
    }

    const handleCancel = () => {
        setSelectedIsRange('')
        setIsRangBox(false)
        dispatch(closeRechargePlanModal());
        setRechargePlanData(prevState => ({
            ...prevState,
            PlanName: '',
            NetworkID: '',
            IsRange: '',
            MinPrice: '',
            MaxPrice: '',
            RechargePrice: '',
            PlanDesc: '',
            aggregatorData: [],
        }));
    }

    const handleDelete = (id, status) => {
        setPlanItemId(id)
        dispatch(deleteRechargePlan(id, status, rechargePlanSearchData.network))
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleIsRange = () => {
        setSelectedIsRange('')
        if (selectedIsRange) {
            setIsRangBox(isRangeBox === false ? true : false)
        }
        else {
            setIsRangBox(!isRangeBox)
        }
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetRechargePlanMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetRechargePlanMessage());
    }

    const handleNetworkChange = (e) => {
        const networkid = e.target.value
        dispatch(getAggregatorByNetworkIDForPlan(networkid));
    }

    const options = networkResult.map(network => ({
        label: network.NetworkName,
        value: network.ID,
    }));
    const onRechargePlanSubmit = (data) => {
        const networkId = data.network.value
        setRechargePlanSearchData(prev => (
            {
                ...prev,
                network: networkId
            }))
        setSearched(true);
        dispatch(getRechargePlan(networkId));

    }

    return (
        <CardLayout title="Recharge Plan">
            {showAlert && !addRechargePlanError && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <form onSubmit={handleRechargePlanSubmit(onRechargePlanSubmit)}>
                <div className="form-row planNetwork">
                    <div className="col-lg-3 col-md-3">
                        <label>Network</label>
                        <Controller
                            control={searchControl}
                            name="network" {...registerregister("network", {
                                required: true,
                            })}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                            }) => (
                                <Select
                                    options={options}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                        />
                        {errorerror.network?.type === "required" && (<div class="invalid-feedback">Network is required!</div>)}
                    </div>
                    <div className="networkSearchBtn">
                        <Button title="Search" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
                    </div>

                </div>
            </form>
            {searched ? <div className="row align-items-baseline">
                <div className='col-lg-6 col-md-6 od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <div className='col-lg-6 col-md-6 text-right'>
                    <SideButtons title="Add Recharge Plan" buttonStyle="primary" onClick={handleOpenAddFormModal} />
                </div>
            </div> : ''}
            {searched ? <Table columns={columns} loading={getRechargePlanLoading} data={dataSource} pagination={true} />
                : ''}
            <Modal
                showModal={rechargePlanModal}
                title={formTitle}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >

                {showAlert && addRechargePlanError && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Plan Name</label>
                            <input type="text" placeholder="Enter Plan Name" className="form-control"
                                name="PlanName" {...register("PlanName", {
                                    required: true,
                                })} />
                            {errors.PlanName?.type === "required" && (
                                <div className="invalid-feedback">Plan name is required !</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label>Network</label>
                            <select className="form-control" placeholder="Select Country" name="NetworkID"  {...register("NetworkID", {
                                required: true,
                            })} disabled={editFlag ? true : false} onChange={handleNetworkChange}>
                                <option value="" key="0">Select</option>
                                {networkResult.length > 0 && networkResult.map(network => (
                                    <option name="NetworkID" value={network.ID} key={network.ID}>
                                        &nbsp;&nbsp; {network.NetworkName}</option>
                                ))}
                            </select>
                            {errors.NetworkID?.type === "required" && (
                                <div className="invalid-feedback">Network is required !</div>
                            )}
                        </div>
                        <div className={`col-md-6 mb-3`}>
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" id="customCheckbox1" value="IsRange" name="customCheckbox1" disabled={editFlag ? true : false} className="custom-control-input" {...register('IsRange')} />
                                <label className="custom-control-label" for="customCheckbox1" onClick={editFlag ? () => false : handleIsRange}> Is Range</label>
                            </div>
                        </div>

                        {(isRangeBox || selectedIsRange) &&
                            <div className='col-md-6 form-row mb-3'>
                                <div className="col-md-6">
                                    <label>Min Price</label>
                                    <input type="text" placeholder="Enter Min Price" disabled={editFlag ? true : false} className="form-control"
                                        name="MinPrice" {...register("MinPrice", {
                                            required: true,
                                        })} />
                                    {errors.MinPrice?.type === "required" && (
                                        <div className="invalid-feedback">Min Price is required !</div>

                                    )}
                                </div>

                                <div className="col-md-6">
                                    <label>Max Price</label>
                                    <input type="text" placeholder="Enter Max Price" disabled={editFlag ? true : false} className="form-control"
                                        name="MaxPrice" {...register("MaxPrice", {
                                            required: true,
                                        })} />
                                    {errors.MaxPrice?.type === "required" && (
                                        <div className="invalid-feedback">Max Price is required !</div>

                                    )}
                                </div>
                            </div>
                        }

                        {!isRangeBox && !selectedIsRange && <div className={`col-md-6 mb-3`}>
                            <label>Plan Price</label>
                            <input type="text" placeholder="Enter Plan Price" disabled={editFlag ? true : false}
                                className="form-control"
                                name="RechargePrice" {...register("RechargePrice", {
                                    required: isRangeBox ? false : true,
                                })} />
                            {errors.RechargePrice?.type === "required" && (
                                <div className="invalid-feedback">Recharge Plan Price is required !</div>
                            )}
                        </div>}

                        <div className="col-md-12">
                            {fields && fields.length ?
                                <div className="">
                                    <p style={{ fontWeight: '600', color: '#3f414d' }}>Aggregator Data</p>
                                    <hr />
                                </div> : ''}
                            <div className="form-row">
                                {fields.map((field, index) => (
                                    <div className="col-md-6 mb-3" key={field.id}>
                                        <label>{field.key}</label>
                                        <input type="text" className="form-control" placeholder="Aggregator Plan ID"
                                            {...register(`aggregatorData.${index}.value`, { required: "Value is required !" })} />
                                        {errors.aggregatorData?.[index]?.value && (
                                            <div className="invalid-feedback">{errors.aggregatorData[index].value.message}</div>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>






                        <div className="col-md-12 mb-3">
                            <label>Plan Desc</label>
                            <textarea placeholder="Write Description..." rows={6} cols={50} className="form-control" name="PlanDesc" {...register("PlanDesc", {
                                required: true,
                            })} />
                            {errors.PlanDesc?.type === "required" && (
                                <div className="invalid-feedback">Plan Desc is required !</div>
                            )}
                        </div>

                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={addRechargePlanLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal
                showModal={preferedAggregatorModal}
                title={formTitle}
                onHide={handleCancelModal}
                width={550}
                showFooter={false}>

                <form onSubmit={handleSubmitReset(handleUpdatePrefferedAggregator)}>
                    <div className="col-md-12 mb-3 p-0">
                        <label>Recharge Aggregator</label>
                        <select className="form-control form-select" placeholder="Select Aggregator" name="RechargeAggregatorId"
                            {...registerReset('RechargeAggregatorId', { required: true })} >
                            <option value="" key="0" disabled>Select Aggregator</option>
                            {
                                listGetByNetworkId && listGetByNetworkId.length && listGetByNetworkId.map((item, index) => {
                                    return <option value={item.ID} key={item.ID}> {item.AggregatorName}</option>
                                    {/* <option value={item.ID} key={item.ID} disabled={item.AggregatorName == "PrepaidIQ" ? false : true}> {item.AggregatorName}</option> */ }
                                })
                            }
                        </select>
                        {errorsReset.RechargeAggregatorId?.type === "required" && (<div className="invalid-feedback">Recharge Aggregator is required !</div>)}
                    </div>

                    <div className='text-right row my-2'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancelModal}>Close</button>
                            <Button title={buttonText} loading={buttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>


            </Modal>

        </CardLayout>
    )
}

export default RechargePlan
