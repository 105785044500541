import moment from "moment";
import momentTimezone from 'moment-timezone';

export const convertDateToRequiredFormatTZ = (datetime) => {
    if (datetime) {
        try {
            let dateT = moment(new Date(datetime)).tz(process.env.REACT_APP_TIMEZONE).format(process.env.REACT_APP_DATE_FORMAT);
            return dateT;
        } catch (error) {
            console.log('error ', error);
            return null;
        }
    } else {
        return null;
    }
}

export const convertDateTimeToRequiredFormatTZ = (datetime) => {
    if (datetime) {
        try {
            let dateT = moment(new Date(datetime)).tz(process.env.REACT_APP_TIMEZONE).format(process.env.REACT_APP_LONG_TIME_FORMAT);
            return dateT;
        } catch (error) {
            console.log('error ', error);
            return null;
        }
    } else {
        return null;
    }
}

export const isNumeric = (str) => {
    return /^\d+$/.test(str);
}

/****************************************/

export const extractSixDigitNumbers = (inputString) => {
    // Use a regular expression to find all 6-digit numbers
    const regex = /\b\d{6}\b/g;
    // Match the input string with the regex and return the array of matches
    const matches = inputString.match(regex);
    // Return the array or an empty array if no matches are found
    return matches ? matches : [];
}

export const checkDateIsGreaterThenCurrentDate = (date) => {
    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    if (!datePattern.test(date)) {
        return false; // Invalid format
    }

    let obtainedDate = moment(new Date(date)).format('YYYY-MM-DD');
    let currentDate = moment().format('YYYY-MM-DD');
    if (currentDate <= obtainedDate) {
        return true;
    } else {
        return false;
    }
}

export const twoDigitDecimal = (number) => {
    let twoDigitDecimal = parseFloat(number).toFixed(2);
    return twoDigitDecimal;
}

export const isAdmin = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 1 && tokenData[0].RoleID == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isAdminAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 1 && tokenData[0].RoleID == 2) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isAdminOrAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if ((tokenData[0].IsCompany == 1 && tokenData[0].RoleID == 1) ||
            (tokenData[0].IsCompany == 1 && tokenData[0].RoleID == 2)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isAdminOrDealer = (tokenData) => {
    if (tokenData.length > 0) {
        if ((tokenData[0].IsCompany == 1 && tokenData[0].RoleID == 1) ||
            (tokenData[0].IsCompany == 0 && tokenData[0].RoleID == 1)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isDealer = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 0 && tokenData[0].RoleID == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isDealerAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 0 && tokenData[0].RoleID == 2) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const allowDealerCreation = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany == 0 && tokenData[0].AllowSellerCreation == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validateIMEI = (imei) => {
    // Check if the length is 15
    if (imei == null || imei.length !== 15) {
        return false;
    }

    try {
        let sum = 0;
        for (let i = 0; i < 15; i++) {
            let digit = parseInt(imei.charAt(i), 10);

            // Double every second digit from the right
            if (i % 2 === 1) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }
            sum += digit;
        }

        // Check if the sum is a multiple of 10
        return sum % 10 === 0;
    } catch (e) {
        // If the string contains non-numeric characters
        return false;
    }
}

export const validateSNo = (str, networkId, simType) => {
    if (simType == 26 && str == "") {
        return true;
    } else if (simType == 26 && str != "") {
        return false;
    }

    if (!isNumeric(str)) {
        return false;
    }

    if (str.length) {
        if (networkId == 3 || networkId == 4) {
            return str.length === 20 ? true : false;
        } else {
            return str.length === 19 ? true : false;
        }
    } else {
        return false;
    }
}

export const validateZipCode = (str) => {
    if (!isNumeric(str)) {
        return false;
    }
    if (str.length) {
        return str.length == 5;
    } else {
        return false;
    }
}

export const checkUniqueArray = (array) => {
    const checkSet = new Set(array);
    return checkSet.size === array.length;
}

export const validateMSISDN = (str) => {
    return str.length === 10;
}

export const removeLastCharacterFromString = (str) => {
    return str.toString().slice(0, -1);
}

export const isKeyUnique = (arr, key) => {
    const values = new Set();

    return arr.every(item => {
        if (values.has(item[key])) {
            return false; // Duplicate found, return false to stop the iteration
        }
        values.add(item[key]);
        return true; // Continue the iteration
    });
}

export const validatePin = (str, networkId) => {
    let pattern;
    if (networkId == 3 || networkId == 4 || networkId == 7) {
        pattern = /^\d{4}$/;
    } else {
        pattern = /^\d{6}$/;
    }

    if (pattern && pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}

export const selectCharacters = (string, networkId) => {
    if (string === undefined || string == '') {
        return string;
    }
    if (networkId == 1 || networkId == 2 || networkId == 5 || networkId == 7) {
        return string.trim().slice(0, 19);
    } else if (networkId == 3 || networkId == 4) {
        return string.trim().slice(0, 20);
    } else {
        return string.trim();
    }
}

export const removeLastCharacter = (string) => {
    return string.slice(0, -1);
}

export const uniqArrayOfObject = (array, field) => {
    return array.reduce((accumulator, current) => {
        if (!accumulator.includes(current[field])) {
            accumulator.push(current[field])
        }
        return accumulator;
    }, []
    )
}

export const makeSecondIntoMinuteSecond = (sec) => {
    if (sec == null || sec <= 0) {
        return `00:00`;
    }
    let min = sec >= 60 ? Math.floor(sec / 60) : 0;
    let second = (sec - (min * 60));
    second = ("0" + second.toString()).slice(-2);
    min = ("0" + min.toString()).slice(-2);
    return `${min}:${second}`
}

export const removeSameElementFromArray = (arr) => {
    const uniqueEntries = [];
    const seenEntries = new Set();

    arr.forEach(subArray => {
        const key = subArray.join('|'); // Create a unique key for each sub-array
        if (!seenEntries.has(key)) {
            uniqueEntries.push(subArray);
            seenEntries.add(key);
        }
    });

    return uniqueEntries;
}