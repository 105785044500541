import React, { useState, useRef, useEffect, useMemo } from 'react';
import CardLayout from '../../../components/utils/CardLayout';
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import { useDispatch, useSelector } from 'react-redux';
import shortCodeActions from '../../../redux/shortCodeData/actions'
import networkActions from '../../../redux/network/actions';
import authActions from '../../../redux/auth/actions';
import dealerAction from '../../../redux/dealer/actions'
import { isDealer, isAdmin, isAdminAgent } from '../../../helpers/commonFunction';
import Button from '../../../components/utils/Button';
import SearchBar from '../../../components/utils/SearchBar';
import SideButtons from '../../../components/utils/SideButtons';
import Table from '../../../components/utils/Table'
import DailyPlanRequestForm from './DailyActsPlanForm'
import dailyPlanRequestActions from '../../../redux/dailyPlanRequest/actions'
import { convertDateToRequiredFormatTZ, convertDateTimeToRequiredFormatTZ } from '../../../helpers/commonFunction';
import Tag from '../../../components/utils/Tag';
import Alert from '../../../components/utils/Alert';
import CompleteDailyPlanRequest from './CompleteDailyPlanRequest'
import CancelDailyPlanRequest from './CancelDailyPlanRequest';
import BulkDailyPlanActivation from './BulkDailyPlanActivation';


const DailyActsPlan = () => {
    const dispatch = useDispatch();
    const { dailyRequestResult, dailyRequestListLoading, buttonLoading, dailyRequestMessage, Lockerror, Lockmessage, realselockerror, realselockmessage, lockLoading, releaselockloading, cancelmessage, completeDailyPlanRequestMessage, checkDailyPlanCompletionStatusMessage, createBulkActivationForDailyPlanMessage, createBulkActivationForDailyPlanError } = useSelector((state) => state.dailyPlanRequest);
    const { getDailyPlanRequestList, prepareDailyPlanRequestModal, resetDailyPlan, unLockDailyPlanRequest, lockDailyPlanRequest, prepareCancelModal } = dailyPlanRequestActions;
    const { getUserDataByToken } = authActions;
    const { getDealer } = dealerAction;
    const { getActiveNetworkDAilyPlanMapping } = networkActions;
    const { getShortCode } = shortCodeActions;
    const [filterText, setFilterText] = useState("");
    const { tokenData } = useSelector((state) => state.auth);
    const { shortCodeResult } = useSelector((state) => state.shortCode);
    const { networkResultData } = useSelector((state) => state.network);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const TIMEZONE = process.env.REACT_APP_TIMEZONE;
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const [toDate, setToDate] = useState(new Date())
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [searchData, setSearchData] = useState({
        statusID: '',
        serialNumber: '',
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        dealerID: "",
        networkID: ""
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [completeModal, setCompleteModal] = useState(false);
    const [row, setRow] = useState(new Object());
    const [selectedLockId, setSelectedLockId] = useState('');
    const [transactionID, setTransactionID] = useState(null);
    const [cancelID, setcancelID] = useState(null);
    const [dailyPlanRequestID, setdailyPlanRequestID] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);

    const handleCloseModal = () => {
        setCompleteModal(false);
    }

    const { register: registerFilter, handleSubmit: handleSubmit, formState: { errors: filterError }, watch, reset, getValues, setValue } = useForm({ defaultValues: searchData });

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };
    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };
    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ACTION', key: 'action' },
        { displayName: 'FROM DATE', key: 'RequestedDtTm' },
        { displayName: 'DEALER', key: 'dealer' },
        { displayName: 'STATUS', key: 'Status' },
        { displayName: 'EMAIL', key: 'email' },
        { displayName: 'IMEI', key: 'IMEI' },
        { displayName: 'TRANSACTION ID', key: 'TransactionID' },
        { displayName: 'SERIAL NUMBER', key: 'SerialNumber' },
        { displayName: 'NETWORK', key: 'networkName' },
        { displayName: 'SIM TYPE', key: 'simType' }, ,
        { displayName: 'AMOUNT', key: 'amountCharged' },
        { displayName: 'ZIP CODE', key: 'areaCode' },
        { displayName: 'TO DATE', key: 'toDate' },
        { displayName: 'DAYS', key: 'days' },
        { displayName: 'REMARK', key: 'Remark' },
    ];
    useEffect(() => {
        dispatch(getDealer());
        dispatch(getActiveNetworkDAilyPlanMapping());
        dispatch(getShortCode({
            tableName: "activationrequest",
            fieldName: "RequestStatus"
        }));
        const newData = {
            ...searchData,
            toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            simType: 25,
        };
        dispatch(getDailyPlanRequestList(newData))
    }, [])

    useEffect(() => {
        if (networkResultData.length === 1) {
            setValue('networkID', networkResultData[0].ID);
        }
    }, [networkResultData, setValue]);



    useEffect(() => {
        if (dailyRequestMessage !== null && dailyRequestMessage !== undefined) {
            displayAlert(dailyRequestMessage, 'alert-success');
        }
        if (Lockmessage !== null && Lockmessage !== undefined) {
            displayAlert(Lockmessage, 'alert-success');
        }
        if (Lockerror !== null && Lockerror !== undefined) {
            displayAlert(Lockerror, 'alert-danger');
        }
        if (realselockmessage !== null && realselockmessage !== undefined) {
            displayAlert(realselockmessage, 'alert-success');
        }
        if (realselockerror !== null && realselockerror !== undefined) {
            displayAlert(realselockerror, 'alert-danger');
        }
        if (cancelmessage !== null && cancelmessage !== undefined) {
            displayAlert(cancelmessage, 'alert-success');
        }
        if (completeDailyPlanRequestMessage !== null && completeDailyPlanRequestMessage !== undefined) {
            displayAlert(completeDailyPlanRequestMessage, 'alert-success');
        }
        if (checkDailyPlanCompletionStatusMessage !== null && checkDailyPlanCompletionStatusMessage !== undefined) {
            displayAlert(checkDailyPlanCompletionStatusMessage, 'alert-success');
        }
        if (createBulkActivationForDailyPlanMessage !== null && createBulkActivationForDailyPlanMessage !== undefined) {
            displayAlert(createBulkActivationForDailyPlanMessage, 'alert-success');
        }
        if (createBulkActivationForDailyPlanError !== null && createBulkActivationForDailyPlanError !== undefined) {
            displayAlert(createBulkActivationForDailyPlanError, 'alert-danger');
        }

    }, [createBulkActivationForDailyPlanMessage, createBulkActivationForDailyPlanError, dailyRequestMessage, Lockmessage, Lockerror, realselockmessage, realselockerror, cancelmessage, completeDailyPlanRequestMessage, checkDailyPlanCompletionStatusMessage]);

    const handleOpenAddFormModal = () => {
        dispatch(prepareDailyPlanRequestModal());
    }
    const onSubmit = (data) => {
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSearchData(newData)
        dispatch(getDailyPlanRequestList(newData));
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetDailyPlan());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetDailyPlan());
    }

    const filteredItems = Array.isArray(dailyRequestResult) && dailyRequestResult.length > 0 ? dailyRequestResult.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];

    const handleCompleteForm = (row) => {
        let formData = getValues();
        const newData = {
            ...formData,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSearchData(newData)
        setRow(row);
        setCompleteModal(true);
    }

    const handleCancelForm = (id, transactionID) => {
        dispatch(prepareCancelModal(id));
        setcancelID(id)
        setTransactionID(transactionID);
    };


    const handleLockDeailyPlan = (Id) => {
        let request = {
            activationId: Id
        }
        setdailyPlanRequestID(Id)
        dispatch(lockDailyPlanRequest(request, searchData))

    }

    const handleUnlockDeailyPlan = (Id) => {
        let request = {
            activationId: Id
        }
        setSelectedLockId(Id)
        dispatch(unLockDailyPlanRequest(request, searchData))
    }
    const data = useMemo(() => {
        if (filteredItems && filteredItems.length) {
            return filteredItems.map((item, key) => {
                return {
                    key: key + 1,
                    TransactionID: item.TransactionID,
                    SerialNumber: item.SerialNumber,
                    IMEI: item.IMEI,
                    networkName: item.NetworkName,
                    simType: item.SimTypeValue,
                    email: item.CustomerEmailID,
                    areaCode: item.AreaCode,
                    dealer: item.DealerName,
                    amountCharged: `$${item.AmountCharged.toFixed(2)}`,
                    days: item.RequestedDays,
                    RequestedDtTm: item.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(item.RequestedDtTm) : '',
                    toDate: item.RequestedToDate !== null ? convertDateToRequiredFormatTZ(item.RequestedToDate) : '',
                    Status: <Tag color={`${(item.RequestStatus === 13) ? 'badge-danger' : (item.RequestStatus === 11) ? 'badge-warning' : (item.RequestStatus === 12) ? 'badge-success' : 'badge-secondary'}`} title={`${(item.RequestStatus === 13) ? 'Cancelled' : (item.RequestStatus === 11) ? 'Processing' : (item.RequestStatus === 12) ? 'Processed' : 'Pending'}`}> </Tag>,
                    Remark: item.Remark,
                    action:
                        <> {(isAdmin(tokenData) || isAdminAgent(tokenData)) && (
                            item.RequestStatus === 10 && (
                                item.lockFlag === 0 ? (
                                    item.lockFlag === 0 && item.otherLockFlag === 0 ?
                                        <div>
                                            {lockLoading && dailyPlanRequestID == item.ID ? <div style={{ marginLeft: "5px" }} class="spinner-border spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div> : <div className="icon iq-action-icon-box rounded-circle" style={{ backgroundColor: 'purple' }} >
                                                <span onClick={() => handleLockDeailyPlan(item.ID)}><i className="ri-lock-line" style={{ color: '#fff' }}></i></span>
                                            </div>}
                                        </div>
                                        :
                                        <div className="icon iq-action-icon-box rounded-circle bg-danger">
                                            <i className="ri-spam-2-line"></i>
                                        </div>
                                ) : (
                                    <div className='d-flex'>
                                        <div className="icon iq-action-icon-box rounded-circle bg-success" onClick={() => handleCompleteForm(item)}>
                                            <i className="ri-check-line"></i>
                                        </div>
                                        <div className="icon iq-action-icon-box rounded-circle bg-danger" onClick={() => handleCancelForm(item.ID, item.TransactionID)}>
                                            <i className="ri-close-fill"></i>
                                        </div>
                                        {releaselockloading && dailyPlanRequestID == item.ID ? (
                                            <div style={{ marginTop: "5px" }} className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>
                                        ) : (
                                            <div className="icon iq-action-icon-box rounded-circle bg-secondary" onClick={() => handleUnlockDeailyPlan(item.ID)}> <i className="ri-lock-unlock-line" style={{ color: '#fff' }}></i> </div>
                                        )}
                                    </div>
                                )
                            )

                        )}
                        </>
                    // <>
                    //     {isAdmin(tokenData) && isAdminAgent(tokenData) && (
                    //         item.RequestStatus === 10 && (
                    //             item.lockFlag === 0 ? (
                    //                 item.lockFlag === 0 && item.otherLockFlag === 0 ? (
                    //                     <div>
                    //                         {lockLoading && dailyPlanRequestID === item.ID ? (
                    //                             <div style={{ marginLeft: "5px" }} className="spinner-border spinner-border-sm" role="status">
                    //                                 <span className="sr-only">Loading...</span>
                    //                             </div>
                    //                         ) : (
                    //                             <div className="icon iq-action-icon-box rounded-circle" style={{ backgroundColor: 'purple' }}>
                    //                                 <span onClick={() => handleLockDeailyPlan(item.ID)}>
                    //                                     <i className="ri-lock-line" style={{ color: '#fff' }}></i>
                    //                                 </span>
                    //                             </div>
                    //                         )}
                    //                     </div>
                    //                 ) : (
                    //                     <div className="icon iq-action-icon-box rounded-circle bg-danger">
                    //                         <i className="ri-spam-2-line"></i>
                    //                     </div>
                    //                 )
                    //             ) : (
                    //                 <div className='d-flex'>
                    //                     <div className="icon iq-action-icon-box rounded-circle bg-success" onClick={() => handleCompleteForm(item)}>
                    //                         <i className="ri-check-line"></i>
                    //                     </div>
                    //                     <div className="icon iq-action-icon-box rounded-circle bg-danger" onClick={() => handleCancelForm(item.ID, item.TransactionID)}>
                    //                         <i className="ri-close-fill"></i>
                    //                     </div>
                    //                     {releaselockloading && dailyPlanRequestID === item.ID ? (
                    //                         <div style={{ marginTop: "5px" }} className="spinner-border spinner-border-sm" role="status">
                    //                             <span className="sr-only">Loading...</span>
                    //                         </div>
                    //                     ) : (
                    //                         <div className="icon iq-action-icon-box rounded-circle bg-secondary" onClick={() => handleUnlockDeailyPlan(item.ID)}>
                    //                             <i className="ri-lock-unlock-line" style={{ color: '#fff' }}></i>
                    //                         </div>
                    //                     )}
                    //                 </div>
                    //             )
                    //         )
                    //     )}
                    // </>


                }
            });

        } else {
            return [];
        }
    }, [filteredItems]);

    const dailyBulkActivation = () => {
        setPageNumber(1);
    }

    const backDailyBulkActivation = () => {
        setPageNumber(0);
    }

    return (
        <>
            {
                pageNumber === 1 ? <BulkDailyPlanActivation back={backDailyBulkActivation} /> :
                    <CardLayout title="Daily Acts Plan">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                                <div className="col-lg-2 col-md-4 mb-2">
                                    <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                                    <select
                                        className="form-control"
                                        placeholder="Select Network"
                                        name="networkID"
                                        {...registerFilter("networkID", { required: 'Network is required !' })}
                                    >
                                        <option value="" disabled>Select</option>
                                        {networkResultData.length > 0 && networkResultData.map(network => (
                                            <option name="network" value={network.ID} key={network.ID}>
                                                {network.NetworkName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-2 col-md-4 mb-2">
                                    <label>Select Status</label>
                                    <select className="form-control" id="statusID" name="statusID" placeholder="Select"  {...registerFilter("statusID", {})}>
                                        {shortCodeResult && shortCodeResult.length > 0 && <option value="" name="All">All</option>}
                                        {shortCodeResult && shortCodeResult.length > 0 &&
                                            shortCodeResult.filter(item => item.ID !== 27 && item.ID !== 32).map((item, ind) => (<option key={item.ID} value={item.ID}>{item.Value}</option>))
                                        }
                                    </select>
                                </div>


                                {
                                    (isAdmin(tokenData) || isAdminAgent(tokenData)) &&
                                    <div className="col-lg-2 col-md-4  mb-2">
                                        <label>Select Dealer</label>
                                        <select className="form-control" id="dealerID" name="dealerID"  {...registerFilter("dealerID", {
                                        })} >
                                            <option value="" name="All">All</option>
                                            {
                                                dealerResult && dealerResult.length && dealerResult.map((item) => {
                                                    return <option value={item.ID} key={`dealer${item.ID}`}>{item.DealerName}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                }

                                <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                    <label>From Date</label>
                                    <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                    <DatePicker
                                        ref={datePickerRef}
                                        selected={fromDate}
                                        className="form-control"
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        name="fromDate"
                                        showMonthDropdown
                                        showYearDropdown
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        value={fromDate}
                                    />
                                </div>

                                <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                    <label>To Date</label>
                                    <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                    <DatePicker
                                        ref={datePickerRef2}
                                        selected={toDate}
                                        className="form-control center"
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        name="todate"
                                        showMonthDropdown
                                        showYearDropdown
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        value={toDate}
                                    />
                                </div>

                                <div className="col-lg-2 col-md-4 mb-2">
                                    <label>SIM/Mobile/TXN</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="serialNumber"
                                        name="serialNumber"
                                        placeholder="SIM/Mobile/TXN"
                                        {...registerFilter("serialNumber")}
                                    />
                                </div>

                                <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? 'col-lg-2 col-md-4 mb-2' : 'col-lg-2 col-md-4'}>
                                    <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? "mt-2 d-flex justify-content-start activation-search-btn" : "mt-4"}>
                                        <Button title="Search" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} loading={buttonLoading} buttonType='submit' />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <hr />
                        {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                        <div className="row mb-3  ">
                            <div className='col-lg-3  activation-request-search-btn mb-sm-444'>
                                <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                            </div>
                            <div className='col-lg-9 d-flex justify-content-end activation-request-action-btn '>
                                {tokenData && tokenData.length && tokenData[0].ParentID === 1 ? (
                                    <>
                                        <SideButtons
                                            title="Add Bulk Daily Plan"
                                            className="btn btn-success succ-btn-net font-weight-bold mr-2"
                                            onClick={() => dailyBulkActivation()}
                                        />
                                        <SideButtons
                                            title="Add Daily Plan"
                                            className="btn btn-primary succ-btn-net font-weight-bold mr-2"
                                            onClick={() => handleOpenAddFormModal()}
                                        />
                                    </>
                                ) : ''}
                            </div>
                        </div>

                        <div id={'DailyPlanRequestTable'}>
                            <Table columns={columns} data={data} loading={dailyRequestListLoading} pagination={true} />
                        </div>
                        <DailyPlanRequestForm searchData={searchData} />
                        {completeModal && <CompleteDailyPlanRequest completeModal={completeModal} handleCloseModal={handleCloseModal} row={row} searchData={searchData} />}
                        <CancelDailyPlanRequest transactionID={transactionID} cancelID={cancelID} searchData={searchData} />
                    </CardLayout>
            }
        </>
    )
}

export default DailyActsPlan;